import { App } from "../types"
import { create } from "zustand"
import { menus } from "./menus"

const useGlobalStore = create<App.State>((set) => ({
	showKlaviyoBISPopup: false,
	showSlideoutCart: false,
	showSlideoutMenu: false,
	myshopifyDomain: "brando-no.myshopify.com",
	activeSegmentMenu: null,
	activeSegmentMenuTitle: menus.activeSegmentMenuTitle,
	routeChangeStart: false,
	userWishListItems: [],
	userWishListIsVisible: false,
	cart: null,
	productPageInventoryData: null,
	search: {
		query: new URL("https://brando.no/").searchParams.get("q") || "",
		searchSegmentedMenus: menus.mobileMenu,
		activeSearchSegmentFilterTag: null,
		collectionPageQuery: "",
		searchInProgress: false,
		openInlineSearchContainer: false
	},
	setSearch: (searchState: Partial<App.State["search"]>) => {
		set((currentState) => {
			return {
				...currentState,
				search: {
					...currentState.search,
					...searchState
				}
			}
		})
	},
	locationPickerModal: {
		show: false,
		withCartInfo: true
	},
	locations: [],
	selectedLocation: null
}))

export { useGlobalStore }
