import find from "lodash/find"
import map from "lodash/map"
import filter from "lodash/filter"
import cloneDeep from "lodash/cloneDeep"
import forEach from "lodash/forEach"
// import humanize from 'underscore.string/humanize'
import each from "lodash/each"
import isArray from "lodash/isArray"
import debounce from "lodash/debounce"
import sortBy from "lodash/sortBy"
import uniqBy from "lodash/uniqBy"
import groupBy from "lodash/groupBy"
import reject from "lodash/reject"
import isObject from "lodash/isObject"
import isNumber from "lodash/isNumber"
import isEqual from "lodash/isEqual"

const lodash = {
	reject,
	groupBy,
	isEqual,
	isObject,
	sortBy,
	debounce,
	isArray,
	find,
	forEach,
	each,
	map,
	uniqBy,
	filter,
	cloneDeep,
	isNumber,
	// humanize: (phrase: string): string => {
	//    return humanize(phrase)
	// },

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	replaceAll: (
		phrase: string,
		searchValue: string,
		replaceWith: string,
		ignoreCase?: boolean
	): string => {
		// ensure phrase is a string
		if (typeof phrase !== "string") {
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			console.error(`phrase(${phrase}) must be a string`)
			return phrase
		}

		return phrase.split(searchValue).join(replaceWith)
	}
}

export default lodash
