import _ from "../shared/utils/lodash"
import { CSSProperties, Fragment, useCallback, useEffect, useRef } from "react"
import { createPortal } from "react-dom"
import { useGlobalStore } from "~/shared/useGlobalStore"
// import bodyScrollLock from "../utils/bodyScrollLock"

import SVGIcon from "./ui/SVGIcon"

// track every caller and only undo the action after all callers undor their individual call
let callers: Array<string> = []
export const toggleThirdPartyAppWidgetVisibility = (opts: {
	visibility: "hidden" | "visible"
	caller:
		| `BottomSheetModal:${string}${string}`
		| "SlideoutCart"
		| "ToastNotification"
		| "AddToCartWidget"
		| "MobileNavMenu"
}) => {
	if (opts.visibility === "hidden") {
		document.body.classList.add("hide-apps")
		if (callers.includes(opts.caller) === false) {
			callers.push(opts.caller)
		}
	} else {
		callers = _.filter(callers, (coller) => opts.caller !== coller)
		if (callers.length === 0) {
			document.body.classList.remove("hide-apps")
		}
	}
}

type Props = {
	id: string
	show: boolean
	header?: JSX.Element
	body: JSX.Element
	footer?: null | JSX.Element
	style?: CSSProperties
	className?: string
	zIndex?: number
	onRequestHideCb: () => void
	renderWithCreatePortal?: boolean
}

const BottomSheetModal = (props: Props) => {
	const containerRef = useRef<HTMLDivElement>(null)
	const backdropRef = useRef<HTMLDivElement>(null)

	const onRequestHideCb = props.onRequestHideCb
	const animateCb = useCallback(
		(opts: { mode: "show" | "hide" }) => {
			if (containerRef.current == null) {
				return
			}

			if (opts.mode === "show") {
				// bodyScrollLock.unlock()

				containerRef.current.classList.add("visible")
				if (backdropRef.current) {
					backdropRef.current.style.opacity = "0.5"
				}
			} else {
				containerRef.current.classList.remove("visible")
				if (backdropRef.current) {
					backdropRef.current.style.opacity = "0"
				}
			}

			if (opts.mode === "hide") {
				setTimeout(() => {
					onRequestHideCb()
				}, 300)
			}
		},
		[onRequestHideCb]
	)

	useEffect(() => {
		animateCb({
			mode: props.show === true ? "show" : "hide"
		})

		toggleThirdPartyAppWidgetVisibility({
			visibility: props.show === true ? "hidden" : "visible",
			caller: `BottomSheetModal:${props.id}`
		})
	}, [props.show, animateCb, props.id])

	const zIndex = props.zIndex != null ? props.zIndex : 100000

	const elem = (
		<Fragment>
			<div
				ref={backdropRef}
				style={{
					display: props.show === true ? "block" : "none",
					position: "fixed",
					top: 0,
					left: 0,
					width: "100%",
					height: "100%",
					zIndex: zIndex - 1,
					backgroundColor: "#000",
					opacity: 0.5,
					willChange: "opacity",
					transition: "opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1)"
				}}
				onClick={() => {
					animateCb({ mode: "hide" })
				}}
			/>
			<div
				id={props.id}
				className={`bottom-sheet-modal flex flex-col 
				 ${props.className || ""}`}
				ref={containerRef}
				style={{
					...props.style,
					zIndex
				}}>
				{props.header}

				<div
					style={{
						top: 12,
						right: 12,
						width: 30,
						height: 30,
						zIndex: 10,
						cursor: "pointer",
						borderRadius: "50%",
						position: "absolute",
						background: "white",
						border: "1px solid #f1f1f1",
						boxShadow: "1px 1px 6px rgba(0, 0, 0, 0.05)"
					}}
					data-test="bottom-sheet-modal-close-button"
					onClick={() => {
						animateCb({ mode: "hide" })
					}}>
					<div className="transform-centered">
						<SVGIcon
							name="x"
							size={22}
							color="var(--color-cta)"
						/>
					</div>
				</div>

				<div
					className="overflow-y-auto"
					style={{
						flex: 1,
						height: "100%",
						maxHeight: "100%"
					}}>
					{props.body}
				</div>

				{props.footer}
			</div>
		</Fragment>
	)

	if (props.renderWithCreatePortal === false) {
		return elem
	}

	// TODO: find a better approach to lazy load this while on server
	if (props.show === false) {
		return elem
	}

	return createPortal(elem, document.body)
}

export default BottomSheetModal
