import { ParsedBoostProduct } from "../modules/search/SearchController"
import { locales } from "../shared/locales"
import { App } from "../types"
import { isParsedBoostProduct } from "./product-card/ProductCard"
type Props = {
	className?: string
	product: App.StorefrontAPI.Product | ParsedBoostProduct
}

export const getPercentageDiscount = (
	product: App.StorefrontAPI.Product | ParsedBoostProduct
) => {
	let percentageDiscount = 0
	if (product.compareAtPriceRange?.maxVariantPrice != null) {
		const { maxVariantPrice } = product.compareAtPriceRange

		const compareAtAmount = parseFloat(maxVariantPrice.amount)
		const amount = parseFloat(product.priceRange.maxVariantPrice.amount)

		if (compareAtAmount > 0) {
			percentageDiscount = Math.round(
				((compareAtAmount - amount) / compareAtAmount) * 100
			)
		}
	}

	return percentageDiscount
}

const ProductBadges = (props: Props) => {
	const { product } = props

	const productisAvailable = isParsedBoostProduct(product)
		? product.available
		: product.availableForSale

	const percentageDiscount = getPercentageDiscount(product)

	const tagstoShow = "forhåndssalg|nyhet|bestselger|kun i butikk"

	return (
		<div
			className={`${props.className}`}
			style={{
				zIndex: 3
			}}>
			{productisAvailable != true && (
				<div className="product-badge">{locales.products.product.soldoutBtn}</div>
			)}

			{percentageDiscount > 0 && (
				<>
					<div className="product-badge font-bold">
						{locales.products.general.sale}
					</div>

					<div
						className="product-badge"
						style={{
							backgroundColor: "var(--color-cta)"
						}}>
						{`-${percentageDiscount}%`}
					</div>
				</>
			)}

			{product.tags != null &&
				product.tags.length > 0 &&
				product.tags.map((tag, i) => {
					if (tagstoShow.split("|").includes(tag.toLowerCase())) {
						return (
							<div
								key={i}
								className="product-badge">
								{tag}
							</div>
						)
					}
				})}
		</div>
	)
}
export default ProductBadges
