import * as uuid from "uuid"
import { App } from "../types"
import SWYM_CONFIG from "./swym.config"
import { v4 as uuidV4 } from "uuid"
import { useGlobalStore } from "../shared/useGlobalStore"

const defaultWisthlistName = "Wishlist"

export default class WishListService {
	static async init() {
		const reg = (await createSessionIDAndRegId()).reg
		const list = await this.getOrCreateWishListLid(reg)

		const wishlistItems = await this.getUserWishList({
			regid: reg.regid,
			sessionid: reg.sessionid,
			lid: list.lid
		})
		useGlobalStore.setState({
			userWishListItems: wishlistItems.items
		})
	}

	static async addItemToWishList(data: { empi: number; epi: number; du: string }) {
		const reg = (await createSessionIDAndRegId()).reg
		const list = await this.swymAddProductToWishlist({
			...reg,
			productId: data.empi,
			variantId: data.epi,
			productUrl: data.du
		})
		const wishlistItems = await this.getUserWishList({ ...reg, lid: list.lid })
		useGlobalStore.setState({
			userWishListIsVisible: true,
			userWishListItems: wishlistItems.items
		})
	}

	static async removeItemFromWishList(data: { empi: number; epi: number; du: string }) {
		const reg = (await createSessionIDAndRegId()).reg

		const list = await this.swymDeleteProductFromList({
			...reg,
			productId: data.empi,
			variantId: data.epi,
			productUrl: data.du
		})

		const wishlistItems = await this.getUserWishList({ ...reg, lid: list.lid })
		useGlobalStore.setState({
			userWishListItems: wishlistItems.items.filter((item) => item.empi !== data.empi)
		})
	}

	static createWishListLid = async (args: { regid: string; sessionid: string }) => {
		const url = new URL(`${SWYM_CONFIG.ENDPOINT}`)
		url.pathname = "/api/v3/lists/create"
		url.searchParams.set("pid", SWYM_CONFIG.PID)

		const body = new URLSearchParams()
		body.append("lname", defaultWisthlistName)
		body.append("regid", args.regid)
		body.append("sessionid", args.sessionid)

		const data: {
			lname: string
			lid: string
			// msg: string
			// sessionid: string
			// regid: string
		} = await fetch(url.toString(), {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded"
			},
			method: "POST",
			body: body
		}).then((res) => {
			return res.json()
		})

		return data
	}

	static getOrCreateWishListLid = async (args: { regid: string; sessionid: string }) => {
		const url = new URL(`${SWYM_CONFIG.ENDPOINT}`)
		url.pathname = "/api/v3/lists/fetch-lists"
		url.searchParams.set("pid", SWYM_CONFIG.PID)

		const body = new URLSearchParams()
		body.append("regid", args.regid)
		body.append("sessionid", args.sessionid)

		const lists: Array<{
			lname: string
			lid: string
		}> = await fetch(url.toString(), {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded"
			},
			method: "POST",
			body: body
		}).then((res) => {
			return res.json()
		})

		const list = lists.find((list) => list.lname === defaultWisthlistName)

		if (list == null) {
			return this.createWishListLid({
				...args
			})
		}

		return list
	}

	static getUserWishList = async (args: {
		regid: string
		sessionid: string
		lid: string
	}) => {
		const url = new URL(`${SWYM_CONFIG.ENDPOINT}`)
		url.pathname = "/api/v3/lists/fetch-list-with-contents"
		url.searchParams.set("pid", SWYM_CONFIG.PID)

		const body = new URLSearchParams()
		body.append("regid", args.regid)
		body.append("sessionid", args.sessionid)
		body.append("lid", args.lid)

		const list: {
			lname: string
			lid: string
			items: Array<App.WishlistItem>
		} = await fetch(url.toString(), {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded"
			},
			method: "POST",
			body: body
		}).then((res) => {
			return res.json()
		})

		return list
	}

	static swymAddProductToWishlist = async (args: {
		regid: string
		sessionid: string
		productId: number
		variantId: number
		productUrl: string
	}): Promise<{ lid: string }> => {
		const url = new URL(`${SWYM_CONFIG.ENDPOINT}`)
		url.pathname = "/api/v3/lists/update-ctx"
		url.searchParams.set("pid", SWYM_CONFIG.PID)

		const list = await this.getOrCreateWishListLid(args)

		const body = new URLSearchParams()
		body.append("regid", args.regid)
		body.append("sessionid", args.sessionid)
		body.set("lid", list.lid)
		body.set(
			"a",
			JSON.stringify([
				{
					empi: args.productId,
					epi: args.variantId,
					du: args.productUrl
				}
			])
		)

		// TODO: check if this has errors in the returned data
		const data: {
			a: Array<{ epi: string; empi: string }>
		} = await fetch(url.toString(), {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded"
			},
			method: "POST",
			body: body
		}).then((res) => {
			return res.json()
		})

		return list
	}

	static swymDeleteProductFromList = async (args: {
		regid: string
		sessionid: string
		productId: number
		variantId: number
		productUrl: string
	}) => {
		const url = new URL(`${SWYM_CONFIG.ENDPOINT}`)
		url.pathname = "/api/v3/lists/update-ctx"
		url.searchParams.set("pid", SWYM_CONFIG.PID)

		const list = await this.getOrCreateWishListLid(args)

		const body = new URLSearchParams()
		body.append("regid", args.regid)
		body.append("sessionid", args.sessionid)
		body.set("lid", list.lid)
		body.set(
			"d",
			JSON.stringify([
				{
					empi: args.productId,
					epi: args.variantId,
					du: args.productUrl
				}
			])
		)

		// TODO: check if this has errors in the returned data
		const data: {
			a: Array<{ epi: string; empi: string }>
		} = await fetch(url.toString(), {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded"
			},
			method: "POST",
			body: body
		}).then((res) => {
			return res.json()
		})

		return list
	}
}

const createSessionIDAndRegId = async () => {
	const sessionId = localStorage.getItem("sywm_sessionid") || uuidV4()
	localStorage.setItem("sywm_sessionid", sessionId)

	const body = new URLSearchParams()
	body.set("sessionid", sessionId)

	const reg: {
		regid: string
		sessionid: string
	} = await fetch(`/api/apps/swym`, {
		method: "POST",
		headers: {
			"Content-Type": "application/x-www-form-urlencoded"
		},
		body: body
	}).then((res) => res.json())

	return {
		sessionId,
		reg
	}
}

export const generateRegId = async (args: { sessionid?: null | string }) => {
	const url = new URL(`${SWYM_CONFIG.ENDPOINT}`)
	const urlencoded = new URLSearchParams()

	url.pathname = "/storeadmin/v3/user/generate-regid"

	// TODO: if user is logged in, we should use emal:
	// urlencoded.append("useremail", args.useremail)
	urlencoded.append("uuid", args.sessionid || uuid.v4())

	const data: {
		msg: string
		sessionid: string
		regid: string
	} = await fetch(url.toString(), {
		headers: {
			Authorization: `Basic MVF5MGQ5Y3B4YU1qT3NMT1BZNzZHRXBvTTUxNDYyUDdwR3d5VUlCMCtrOD06cVJYVTlxUE9ONy1qMmlod08wOTdDZmZ0UzhfSGhNVG4xelpKUHVwc2kxbUFDUUFlUEVPQmlodjd2N1RBcHJ4WEhwczl1bllRRWVWeEhUT2w1Y25NWmctb1c1LWZKdEZLT2pPbGhFRDZRQ0RJbUhvWHJEZldBbGFGWUh4YzUtTmVUbTV1WjY0bUdER05IUTctNFE=`,
			"Content-Type": "application/x-www-form-urlencoded"
		},
		method: "POST",
		body: urlencoded
	}).then((res) => {
		return res.json()
	})

	return data
}
