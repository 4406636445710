import { QueryRootRequest } from "../../../graphql/storefront-api"

export const PRICE_FILTER_ID = "filter.v.price"

// we do this as the simplest way to get the requests to be typed as they are without separate declarationss
function genFieldSelect<T extends QueryRootRequest>(fields: T): T {
	return fields
}

export const PRODUCT_FOR_PRODUCT_CARD_QUERY_REQ = genFieldSelect({
	product: {
		title: true,
		productType: true,
		handle: true,
		vendor: true,
		featuredImage: {
			url: true
		},
		tags: true,
		id: true,
		options: {
			__scalar: true,
			values: true
		},
		descriptionHtml: true,
		compareAtPriceRange: {
			maxVariantPrice: {
				__scalar: true
			},
			minVariantPrice: {
				__scalar: true
			}
		},
		priceRange: {
			minVariantPrice: {
				__scalar: true
			},
			maxVariantPrice: {
				__scalar: true
			}
		},
		publishedAt: true,
		onlineStoreUrl: true,
		availableForSale: true,
		// media: [{first: 10}, {nodes: {} }],
		images: [
			{
				first: 10
			},
			{
				nodes: {
					__scalar: true
				}
			}
		],
		variants: [
			{
				first: 50
			},
			{
				nodes: {
					availableForSale: true,
					id: true,
					title: true,
					price: {
						__scalar: true
					},
					image: { __scalar: true },
					selectedOptions: {
						__scalar: true
					}
				}
			}
		]
	}
})

export const COLLECTION_ROUTE_QUERY_REQ = genFieldSelect({
	collection: {
		id: true,
		seo: {
			title: true,
			description: true
		},
		handle: true,
		image: {
			id: true,
			height: true,
			url: true,
			width: true
		},
		title: true,
		onlineStoreUrl: true,
		description: true,
		products: [
			{
				first: 72
			},
			{
				filters: {
					id: true,
					label: true,
					type: true,
					values: {
						input: true,
						__scalar: true
					}
				},
				pageInfo: {
					endCursor: true,
					hasNextPage: true
				},
				nodes: PRODUCT_FOR_PRODUCT_CARD_QUERY_REQ.product
			}
		],
		metafield: [
			{
				namespace: "nav",
				key: "segment"
			},
			{
				value: true
			}
		]
	}
})
