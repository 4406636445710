import { useCallback, useState } from "react"
import { Except } from "type-fest"
import { ParsedBoostProduct } from "../modules/search/SearchController"
import { useGlobalStore } from "../shared/useGlobalStore"
import WishListService from "../swym/WishListService"
import { App } from "../types"
import { isParsedBoostProduct } from "./product-card/ProductCard"
import SVGIcon from "./ui/SVGIcon"

type Props = {
	size?: number
	product: Except<App.StorefrontAPI.Product, "media"> | ParsedBoostProduct
	isEmbeddedInSearchResults?: boolean
}

const WishlistPlusView = (props: Props) => {
	const items = useGlobalStore((state) => state.userWishListItems)

	const [inProgress, setInProgress] = useState(false)

	const { product } = props
	// has to https://brando.no
	const productUrl = `https://brando.no/products/${product.handle}`.replace(
		"//products",
		"/products"
	)

	const id = isParsedBoostProduct(product)
		? product.id
		: parseInt(product.id.replace("gid://shopify/Product/", ""))

	const isAddedToWishList =
		items != null && items.find((item) => item.empi === props.product.id) != null

	const featuredImageSrc = isParsedBoostProduct(product)
		? product.featured_image
		: product.featuredImage?.url

	const onClickCb = useCallback(async () => {
		const variant = product.variants[0]
		let variantId = variant.id
		if (typeof variantId === "string") {
			variantId = parseInt(variantId.replace("gid://shopify/ProductVariant/", ""))
		}

		if (variant == null) {
			console.error("FD: variant is not defined")
			return
		}

		const data = {
			du: productUrl,
			empi: id,
			epi: variantId
			// pr: parseFloat(product.priceRange.maxVariantPrice.amount),
		}

		setInProgress(true)

		if (isAddedToWishList === true) {
			await WishListService.removeItemFromWishList({
				empi: data.empi,
				epi: data.epi,
				du: data.du
			})

			setInProgress(false)
		} else {
			await WishListService.addItemToWishList({
				empi: data.empi,
				epi: data.epi,
				du: data.du
			})
			setInProgress(false)
		}
	}, [product.variants, productUrl, id, isAddedToWishList])

	return (
		<div
			data-test="wishlist-plus"
			style={{
				padding: 4,
				cursor: "pointer",
				position: "relative"
			}}
			onClick={(e) => {
				// do not remove!
				// this is needed to allow the wishlist button to be added inside the add to cart button
				e.preventDefault()
				e.stopPropagation()
				onClickCb()
			}}>
			{inProgress === true && (
				<div className="transform-centered">
					<div className="loader" />
				</div>
			)}

			<SVGIcon
				name="heart"
				style={{
					visibility: inProgress === true ? "hidden" : "visible"
				}}
				size={props.size || 32}
				color="var(--color-cta)"
				fill={isAddedToWishList === true ? "var(--color-cta)" : ""}
			/>
		</div>
	)
}

export default WishlistPlusView
