export function modifyShopifyImageSrc(src: string, opts: { width: number; }) {
	// FastSimon images are already formatted
	if (src.includes('_large.') === true) {
		src = src.replace('_large.', '.')
	}

	// rounding is needed
	const width = Math.round(opts.width)
	const source = src.replace('.jpg', `_${width}x.jpg`).replace('.png', `_${width}x.png`);
	return source;
}
