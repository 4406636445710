import { useEffect, useRef, useState } from "react"

import type { Except } from "type-fest"
import type { App } from "../../types"

import SVGIcon from "../ui/SVGIcon"
import { Link } from "../ui/Link"
import { formatMoney } from "../../shared/utils"
import { ParsedBoostProduct } from "../../modules/search/SearchController"
import _ from "../../shared/utils/lodash"
import AddToCartWidget from "../cart/AddToCartWidget"

import WishlistPlusView from "../WishlistPlusView"
import ProductBadges from "../ProductBadges"
import { locales } from "../../shared/locales"

export const isParsedBoostProduct = (
	product: ParsedBoostProduct | Except<App.StorefrontAPI.Product, "media">
): product is ParsedBoostProduct => {
	return _.isNumber((product as ParsedBoostProduct).price)
}

type Props = {
	product: Except<App.StorefrontAPI.Product, "media"> | ParsedBoostProduct
}

const ProductCard = (props: Props) => {
	const { product } = props

	const [showAddToCartWidget, setShowAddToCartWidget] = useState<null | {
		product: Props["product"]
		showWidget: boolean
	}>({
		product,
		showWidget: false
	})

	const imgAspectRatio = 3 / 4

	const containerRef = useRef<HTMLDivElement>(null)
	const slicedTitle = props.product.title.split(" - ")

	const productPageUrl = `/products/${product.handle}`

	const featuredImageSrc = isParsedBoostProduct(product)
		? product.featured_image
		: product.featuredImage?.url

	const getOtherColorsMetafield = () => {
		// @ts-expect-error
		const otherColorsMetafield = product.metafields?.find((meta) => {
			return meta.namespace === "pGroups" && meta.key === "otherColorsProducts"
		})
		// Guard
		if (otherColorsMetafield == null) {
			return null
		}
		const parsedValue: Array<string> = JSON.parse(otherColorsMetafield.value)
		return parsedValue
	}

	const [otherColorsMetafield, setOtherColorMetafield] = useState(
		getOtherColorsMetafield()
	)

	useEffect(() => {
		return
		// return console.error("prdouct card re-enable me")
		if (otherColorsMetafield != null) {
			return
		}

		// todo: use trpc instead
		fetch(`/api${productPageUrl}`, { method: "POST" })
			.then((res) => {
				return res.json() as any as {
					product: {
						metafield: NonNullable<Props["product"]["metafields"]>[0]
					}
				}
			})
			.then((data) => {
				if (data.product.metafield != null) {
					const parsedValue: Array<string> = JSON.parse(data.product.metafield.value)
					setOtherColorMetafield(parsedValue)
				}
			})
	}, [productPageUrl, product.metafields, otherColorsMetafield])

	const productTitle = slicedTitle[0]
	const productColor = slicedTitle[1]

	const colorCount = otherColorsMetafield?.length

	return (
		<div
			ref={containerRef}
			className="product-card max-md:pb-[5px]"
			style={{
				boxShadow: "0px 0px 8px #EFEFEF",
				overflow: "hidden",
				position: "relative",
				color: "#000",
				width: "30	0px",
				backgroundColor: "#fff",
				borderRadius: 12
			}}>
			<div
				className="header"
				style={{ width: "100%", position: "relative" }}>
				<Link to={productPageUrl}>
					<div
						className="featured-image-container"
						style={{ position: "relative" }}>
						<div
							style={{
								width: "100%",
								position: "relative",
								paddingBottom: `${(1 / imgAspectRatio) * 100}%`
							}}>
							{featuredImageSrc != null && (
								<img
									loading="lazy"
									style={{
										top: 0,
										left: 0,
										zIndex: 2,
										width: "100%",
										height: "100%",
										position: "absolute",
										objectFit: "contain",
										overflow: "hidden",
										borderTopLeftRadius: "10px",
										borderTopRightRadius: "10px",
										backgroundColor: "white"
									}}
									key={product.id}
									src={featuredImageSrc}
								/>
							)}
							<div className="transform-centered">
								<div className="loader" />
							</div>
						</div>
					</div>
				</Link>

				<ProductBadges
					product={product}
					className="absolute top-[5px] left-[5px] space-y-1"
				/>

				{showAddToCartWidget?.product != null && (
					<div
						onClick={() => {
							setShowAddToCartWidget({
								...showAddToCartWidget,
								showWidget: true
							})
						}}
						style={{
							right: 10,
							bottom: 10,
							zIndex: 3,
							width: 24,
							height: 24,
							cursor: "pointer",
							borderRadius: "50%",
							position: "absolute",
							backgroundColor: "#fff",
							boxShadow: "1px 1px 5px #ccc"
						}}>
						<SVGIcon
							style={{
								top: "50%",
								left: "50%",
								position: "absolute",
								transform: "translate(-50%, -50%)"
							}}
							name="plus"
							strokeWidth={1}
							size={14}
						/>
					</div>
				)}
				<div
					className="frosted-bg"
					style={{
						left: 10,
						bottom: 10,
						zIndex: 3,
						cursor: "pointer",
						borderRadius: "10px",
						position: "absolute",
						boxShadow: "1px 1px 5px #ccc"
					}}>
					<WishlistPlusView
						size={22}
						product={product}
						isEmbeddedInSearchResults={false}
					/>
				</div>
			</div>
			<Link to={productPageUrl}>
				<div className="p-2 space-y-1 text-left">
					<div className="text-xs">{product.vendor}</div>
					<div>
						<h6 className="product-card-title">{productTitle}</h6>
						<div
							className="flex space-x-1"
							style={{
								fontSize: 11,
								letterSpacing: 0
							}}>
							<div>{productColor}</div>
							{colorCount != null &&
								colorCount > 1 &&
								` | ${colorCount} ${locales.custom.colors}`}
						</div>
					</div>

					<div
						style={{
							fontSize: "12px"
						}}>
						{product.compareAtPriceRange != null &&
						product.compareAtPriceRange.maxVariantPrice.amount !== "0.0" ? (
							<div className="flex space-x-2">
								<div
									style={{
										color: "gray",
										textDecoration: "line-through"
									}}>
									{formatMoney({
										price: product.compareAtPriceRange.maxVariantPrice
									})}
								</div>
								<div
									style={{
										color: "var(--color-sale-price)"
									}}>
									{formatMoney({
										price: product.priceRange.maxVariantPrice
									})}
								</div>
							</div>
						) : product.priceRange.minVariantPrice.amount ===
						  product.priceRange.maxVariantPrice.amount ? (
							formatMoney({
								price: {
									...product.priceRange.maxVariantPrice,
									amount: product.priceRange.maxVariantPrice.amount
								}
							})
						) : (
							<div>
								{formatMoney({
									price: product.priceRange.minVariantPrice
								})}
								-
								{formatMoney({
									price: product.priceRange.maxVariantPrice
								})}
							</div>
						)}
					</div>
				</div>
			</Link>

			{showAddToCartWidget?.showWidget === true && (
				<AddToCartWidget
					product={showAddToCartWidget.product}
					hideAddToCartWidget={() => {
						setShowAddToCartWidget({
							...showAddToCartWidget,
							showWidget: false
						})
					}}
				/>
			)}
		</div>
	)
}

export default ProductCard
