import { useLocation, useMatches } from "@remix-run/react"
import type { MoneyV2 } from "@shopify/hydrogen/storefront-api-types"

// @ts-expect-error types not available
import typographicBase from "typographic-base"
import { countries } from "../../shared/countries"
import { I18nLocale, Locale } from "../type"

export function missingClass(string?: string, prefix?: string) {
	if (!string) {
		return true
	}

	const regex = new RegExp(` ?${prefix}`, "g")
	return string.match(regex) === null
}

export function isMobileWidth(data?: { width: number }): boolean {
	let isMobile = false
	const targetSize = data ? data.width : 768
	if (typeof window !== "undefined") {
		isMobile = window.innerWidth < targetSize
	}

	return isMobile
}

export function formatText(input?: string | React.ReactNode) {
	if (!input) {
		return
	}

	if (typeof input !== "string") {
		return input
	}

	return typographicBase(input, { locale: "en-us" }).replace(
		/\s([^\s<]+)\s*$/g,
		"\u00A0$1"
	)
}

export function getExcerpt(text: string) {
	const regex = /<p.*>(.*?)<\/p>/
	const match = regex.exec(text)
	return match?.length ? match[0] : text
}

export function isNewArrival(date: string, daysOld = 30) {
	return (
		new Date(date).valueOf() >
		new Date().setDate(new Date().getDate() - daysOld).valueOf()
	)
}

export function isDiscounted(price: MoneyV2, compareAtPrice: MoneyV2) {
	if (compareAtPrice?.amount > price?.amount) {
		return true
	}
	return false
}

function resolveToFromType(
	{
		customPrefixes,
		pathname,
		type
	}: {
		customPrefixes: Record<string, string>
		pathname?: string
		type?: string
	} = {
		customPrefixes: {}
	}
) {
	if (!pathname || !type) return ""

	/*
	  MenuItemType enum
	  @see: https://shopify.dev/api/storefront/unstable/enums/MenuItemType
	*/
	const defaultPrefixes = {
		BLOG: "blogs",
		COLLECTION: "collections",
		COLLECTIONS: "collections", // Collections All (not documented)
		FRONTPAGE: "frontpage",
		HTTP: "",
		PAGE: "pages",
		CATALOG: "collections/all", // Products All
		PRODUCT: "products",
		SEARCH: "search",
		SHOP_POLICY: "policies"
	}

	const pathParts = pathname.split("/")
	const handle = pathParts.pop() || ""
	const routePrefix: Record<string, string> = {
		...defaultPrefixes,
		...customPrefixes
	}

	switch (true) {
		// special cases
		case type === "FRONTPAGE":
			return "/"

		case type === "ARTICLE": {
			const blogHandle = pathParts.pop()
			return routePrefix.BLOG
				? `/${routePrefix.BLOG}/${blogHandle}/${handle}/`
				: `/${blogHandle}/${handle}/`
		}

		case type === "COLLECTIONS":
			return `/${routePrefix.COLLECTIONS}`

		case type === "SEARCH":
			return `/${routePrefix.SEARCH}`

		case type === "CATALOG":
			return `/${routePrefix.CATALOG}`

		// common cases: BLOG, PAGE, COLLECTION, PRODUCT, SHOP_POLICY, HTTP
		default:
			return routePrefix[type] ? `/${routePrefix[type]}/${handle}` : `/${handle}`
	}
}

export const INPUT_STYLE_CLASSES =
	"appearance-none rounded dark:bg-transparent border focus:border-primary/50 focus:ring-0 w-full py-2 px-3 text-primary/90 placeholder:text-primary/50 leading-tight focus:shadow-outline"

export const getInputStyleClasses = (isError?: string | null) => {
	return `${INPUT_STYLE_CLASSES} ${isError ? "border-red-500" : "border-primary/20"}`
}

export function statusMessage(status: string) {
	const translations: Record<string, string> = {
		ATTEMPTED_DELIVERY: "Attempted delivery",
		CANCELED: "Canceled",
		CONFIRMED: "Confirmed",
		DELIVERED: "Delivered",
		FAILURE: "Failure",
		FULFILLED: "Fulfilled",
		IN_PROGRESS: "In Progress",
		IN_TRANSIT: "In transit",
		LABEL_PRINTED: "Label printed",
		LABEL_PURCHASED: "Label purchased",
		LABEL_VOIDED: "Label voided",
		MARKED_AS_FULFILLED: "Marked as fulfilled",
		NOT_DELIVERED: "Not delivered",
		ON_HOLD: "On Hold",
		OPEN: "Open",
		OUT_FOR_DELIVERY: "Out for delivery",
		PARTIALLY_FULFILLED: "Partially Fulfilled",
		PENDING_FULFILLMENT: "Pending",
		PICKED_UP: "Displayed as Picked up",
		READY_FOR_PICKUP: "Ready for pickup",
		RESTOCKED: "Restocked",
		SCHEDULED: "Scheduled",
		SUBMITTED: "Submitted",
		UNFULFILLED: "Unfulfilled"
	}
	try {
		return translations?.[status]
	} catch (error) {
		return status
	}
}

/**
 * Errors can exist in an errors object, or nested in a data field.
 */
export function assertApiErrors(data: Record<string, any> | null | undefined) {
	const errorMessage = data?.customerUserErrors?.[0]?.message
	if (errorMessage) {
		throw new Error(errorMessage)
	}
}

export const DEFAULT_LOCALE: I18nLocale = Object.freeze({
	...countries.default,
	pathPrefix: ""
})

export function getLocaleFromRequest(request: Request): I18nLocale {
	const url = new URL(request.url)

	// const localization = await getLocalization()
	// const selectedCountry = localization.availableCountries.find((country) => {
	// 	return country.isoCode === country_code
	// })

	// let selectedLocale: I18nLocale = context.storefront.i18n
	// if (selectedCountry != null) {
	// 	selectedLocale = await getSelectedCountryAndChangeLocale({
	// 		selectedCountry: selectedCountry
	// 	})
	// }

	const firstPathPart = "/" + url.pathname.substring(1).split("/")[0].toLowerCase()

	return countries[firstPathPart]
		? {
				...countries[firstPathPart],
				pathPrefix: firstPathPart
		  }
		: {
				...countries["default"],
				pathPrefix: ""
		  }
}

export function usePrefixPathWithLocale(path: string) {
	const [root] = useMatches()
	const selectedLocale = root.data?.selectedLocale ?? DEFAULT_LOCALE

	return `${selectedLocale.pathPrefix}${path.startsWith("/") ? path : "/" + path}`
}

export function useIsHomePath() {
	const { pathname } = useLocation()
	const [root] = useMatches()
	const selectedLocale = root.data?.selectedLocale ?? DEFAULT_LOCALE
	const strippedPathname = pathname.replace(selectedLocale.pathPrefix, "")
	return strippedPathname === "/"
}

/**
 * Validates that a url is local
 * @param url
 * @returns `true` if local `false`if external domain
 */
export function isLocalPath(url: string) {
	try {
		// We don't want to redirect cross domain,
		// doing so could create fishing vulnerability
		// If `new URL()` succeeds, it's a fully qualified
		// url which is cross domain. If it fails, it's just
		// a path, which will be the current domain.
		new URL(url)
	} catch (e) {
		return true
	}

	return false
}

// TODO: fix me
export function formatMoney(opts: { price: MoneyV2 }): string {
	const cents = parseFloat(opts.price.amount) * 100
	let value: 0 | string = ""
	const placeholderRegex = /\{\{\s*(\w+)\s*\}\}/

	function formatWithDelimiters(
		number: number,
		precision = 0,
		thousands = ".",
		decimal = ","
	) {
		if (isNaN(number) || number == null) {
			return 0
		}

		const parts = (number / 100.0).toFixed(precision).split(".")

		const dollarsAmount = parts[0]!.replace(
			/(\d)(?=(\d\d\d)+(?!\d))/g,
			`$1${thousands}`
		)
		const centsAmount = parts[1] ? decimal + parts[1] : ""

		return dollarsAmount + centsAmount
	}

	// todo: get from api backend
	const moneyFormat = "{{ amount_with_comma_separator }} kr"

	const moneyFormatWithoutCurlyBrackets = moneyFormat.match(placeholderRegex)![1]

	switch (moneyFormatWithoutCurlyBrackets) {
		case "amount":
			value = formatWithDelimiters(cents, 2)
			break
		case "amount_no_decimals":
			value = formatWithDelimiters(cents, 0)
			break
		case "amount_with_comma_separator":
			value = formatWithDelimiters(cents, 2, ".", ",")
			break
		case "amount_no_decimals_with_comma_separator":
			value = formatWithDelimiters(cents, 0, ".", ",")
			break
		case "amount_with_apostrophe_separator":
			value = formatWithDelimiters(cents, 0, "'", ".")
			break
	}

	return moneyFormat.replace(placeholderRegex, value + "")
}

export type Flattened<T extends object> = {
	[key in keyof T]: NonNullable<T[key]> extends { nodes: Array<any> }
		? NonNullable<T[key]>["nodes"]
		: T[key]
}

export function flattenShopifyObject<
	SO extends Record<string, any> & { nodes?: Array<any> }
>(shopifyObj: SO): Flattened<SO> {
	const flattened: Partial<Flattened<SO>> = {}
	Object.keys(shopifyObj).forEach((key: keyof SO) => {
		const value = shopifyObj[key]
		if (value != null && value.nodes) {
			flattened[key] = value.nodes
		} else if (value != null && Array.isArray(value.edges)) {
			flattened[key] = value.edges
		} else {
			flattened[key] = value
		}
	})

	return flattened as Flattened<SO>
}
