import { useEffect, useState } from "react"
import _ from "../shared/utils/lodash"

import type { App } from "../types"
type Props = {
	product: {
		// id: string
		handle: string
		options: App.StorefrontAPI.Product["options"]
		variants: Array<
			Pick<
				App.StorefrontAPI.ProductVariant,
				"availableForSale" | "selectedOptions" | "price" | "id" | "title"
			>
		>
	}
	defaultSelectedOptions: null | App.StorefrontAPI.ProductVariant["selectedOptions"]
	// optionIndex: number
	onSelectVariant: (state: null | Props["product"]["variants"][0]) => void
}

type State = {
	selectedOptions: Array<{ name: string; value: string }>
}

function isEqualStrict<T extends object>(o1: T, o2: T) {
	return _.isEqual(o1, o2)
}

function getDefaultSelectedOptions(opts: Props): State["selectedOptions"] {
	const { product } = opts
	if (product.variants.length === 1) {
		const variant = product.variants[0]!
		return [...variant.selectedOptions]
	}

	return []
}

const ProductOptions = (props: Props) => {
	const [state, setState] = useState<State>({
		selectedOptions:
			// spread to prevent mutations
			props.defaultSelectedOptions != null
				? [...props.defaultSelectedOptions]
				: getDefaultSelectedOptions(props)
	})

	const { variants } = props.product

	useEffect(() => {
		const selectedVariant = _.find(variants, (variant) => {
			return isEqualStrict(
				variant.selectedOptions.map((selectedOption) => {
					return {
						value: selectedOption.value,
						name: selectedOption.name
					}
				}),
				state.selectedOptions.map((option) => {
					return {
						value: option.value,
						name: option.name
					}
				})
			)
		})
		props.onSelectVariant(selectedVariant || null)
	}, [state.selectedOptions, variants, props])

	const { product } = props

	return (
		<div className="space-y-2">
			{product.options.map((option, optionIndex) => {
				if (option.name === "Title" && option.values[0] === "Default Title") {
					return null
				}
				return (
					<div
						data-test-option-name={option.name}
						key={option.name + optionIndex}
						className="space-y-4">
						<div
							style={{ width: "100%" }}
							className="flex justify-between">
							<div className="uppercase font-[14px]">{option.name}</div>
							{option.name.toLowerCase() === "STØRRELSE".toLowerCase() && (
								<div id={`SizeFox--${product.handle}`} />
							)}
						</div>
						<div className={"flex flex-wrap"}>
							{option.values.map((value) => {
								let isAvailable = true
								if (props.product.options.length === 1) {
									isAvailable =
										props.product.variants.find((variant) => {
											return (
												variant.selectedOptions[0]?.value === value &&
												variant.availableForSale
											)
										}) != null
								}
								const isLastChild = props.product.options.length === -1

								const isSelected =
									state.selectedOptions[optionIndex]?.value === value

								return (
									<div
										key={value}
										data-test-option-value={value}
										className="widget-product-option-value"
										onClick={() => {
											setState((prevState) => {
												prevState.selectedOptions[optionIndex] = {
													name: option.name,
													value: value
												}
												return {
													...prevState,
													selectedOptions: [...prevState.selectedOptions]
												}
											})
										}}
										style={{
											flexShrink: 0,
											flexGrow: 0,
											lineHeight: "22.4px",
											marginBottom: 5,
											fontWeight: "700",
											cursor: "pointer",
											textAlign: "center",
											textTransform: "uppercase",
											padding: 10,
											marginRight: isLastChild === true ? 0 : 5,
											opacity: isAvailable !== true ? "0.3" : "1",
											color: isSelected === true ? "white" : "black",
											borderRadius: 5,
											border: "2px solid var(--color-main)",
											backgroundColor:
												isSelected === true
													? "var(--color-main)"
													: "transparent"
										}}>
										<div
											data-test={`${value}`}
											style={{
												fontSize: 13,
												minWidth: 20,
												margin: "0 auto",
												overflow: "hidden",
												textAlign: "center"
											}}>
											{value}
										</div>
									</div>
								)
							})}
						</div>
					</div>
				)
			})}
		</div>
	)
}

export default ProductOptions
