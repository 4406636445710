import { useGlobalStore } from "../useGlobalStore"
import { App } from "../../types"

import { GraphqlResponse, QueryRootRequest } from "../../../graphql/storefront-api"

import { linkTypeMap } from "@gqlts/runtime/dist/client/linkTypeMap"

import types from "../../../graphql/storefront-api/types.cjs.js"

import { generateGraphqlOperation } from "@gqlts/runtime/dist/client/generateGraphqlOperation"
import { CompressedTypeMap } from "@gqlts/runtime/dist/types"
import { FieldsSelection } from "@gqlts/runtime"
import { QueryRoot } from "@shopify/hydrogen/storefront-api-types"
import { COLLECTION_ROUTE_QUERY_REQ } from "./queries"
import { FiltersQueryParams } from "../../routes/($lang)/collections/$collectionHandle"

function generateQueryOp(fields: QueryRootRequest & { __name?: string }) {
	const typeMap = linkTypeMap(types as any as CompressedTypeMap<number>)
	return generateGraphqlOperation("query", typeMap.Query as any, fields as any)
}
export const convertCartResponseCartToInitializedCart = (
	cartResponse: App.StorefrontAPI.GraphQLCart
): App.InitializedCart => {
	const cart: App.InitializedCart = {
		...cartResponse,
		lines: cartResponse.lines.edges.map((edge: { node: any }) => edge.node)
	}

	return cart
}

export const storefrontAPIConfig: {
	storeDomain: `${string}${string}.myshopify.com`
	storefrontToken: string
	storefrontApiVersion: string
} = {
	storeDomain: "brando-no.myshopify.com",
	storefrontToken: "570a35df12b89f1e5847e2795d3c8982",
	// storeDomain: 'onthewalldesigns.myshopify.com',
	// storefrontToken: '727a1769e016551e5aa1377151c84dbd',
	// storeDomain: "fitttheme.myshopify.com",
	// storefrontToken: "df403c4f73f5c199b922519fbf14857a",
	storefrontApiVersion: "2023-04"
}

export const FRAGMENTS = {
	cart: `
		id,
		totalQuantity,
		checkoutUrl,
		attributes {
			key,
			value
		},
		cost {
			subtotalAmount {
				amount,
				currencyCode
			},

			totalAmount {
				amount,
				currencyCode
			}
		},
		lines(first: 10) {
			edges {
				node {
					id
					quantity
					cost {
						totalAmount {
							amount,
							currencyCode
						}
					}
					merchandise {
							... on ProductVariant {
							title,
							id,
							product {
								id
								title
								handle
								compareAtPriceRange {
									minVariantPrice{
										amount
										currencyCode
									}
									maxVariantPrice{
										amount
										currencyCode
									}
								}
								priceRange{
									minVariantPrice{
										amount
										currencyCode
									}
									maxVariantPrice{
										amount
										currencyCode
									}
								}
							}
							selectedOptions {
								name,
								value
							}
							image {
								url,
								width,
								height
							}
						}
					}
				}
			}
		}`
}

export class StorefrontAPI {
	config = storefrontAPIConfig
	clientUrl: string
	clientHeaders: {
		"Content-Type": "application/json"
		"X-Shopify-Storefront-Access-Token": string
	}

	constructor() {
		this.clientUrl = `https://${this.config.storeDomain}/api/${this.config.storefrontApiVersion}/graphql.json`
		this.clientHeaders = {
			"Content-Type": "application/json",
			"X-Shopify-Storefront-Access-Token": this.config.storefrontToken
		}
	}

	client(): {
		request<T>(query: string, variables?: Record<string, any>): Promise<T>
	} {
		const clientUrl = this.clientUrl
		const clientHeaders = this.clientHeaders
		function request<T>(query: any, variables?: any) {
			return fetch(clientUrl, {
				headers: {
					...clientHeaders
				},
				method: "POST",
				body: JSON.stringify({ query, variables })
			})
				.then((res) => res.json())
				.then((data) => {
					return (data as { data: T }).data
				})
		}

		return {
			request: request
		}
	}

	async fetchCart(opts: { cartId: string }): Promise<App.InitializedCart> {
		const query = `#graphql
		{
			cart(id: "${opts.cartId}") {
				${FRAGMENTS.cart}
			}
		}
		`
		return this.client()
			.request<{
				cart: App.StorefrontAPI.GraphQLCart
			}>(query, {})
			.then((data) => {
				return convertCartResponseCartToInitializedCart(data.cart)
			})
	}

	isCreatingOrFetchingCart = false

	CART_ID_KEY = "__cart_id__"

	query<R extends QueryRootRequest>(
		request: R,
		args?: {
			locale: App.Loader.Route.Root["selectedLocale"]
		}
	): Promise<GraphqlResponse<FieldsSelection<QueryRoot, R>>> {
		const operation = generateQueryOp(request)

		// if (args?.locale != null) {
		// operation.query = operation.query.replace(
		// 	"){",
		// 	`) @inContext(country: ${args.locale.country}, language: ${args.locale.language}) {`
		// )
		// }

		return fetch(this.clientUrl, {
			headers: {
				...this.clientHeaders
			},
			method: "POST",
			body: JSON.stringify({
				query: operation.query,
				variables: operation.variables
			})
		})
			.then((res) => {
				return res
			})
			.then((res) => res.json())
			.then((data: any) => {
				return data
			})
	}

	async getCart(): Promise<App.InitializedCart> {
		const cart = useGlobalStore.getState().cart
		if (cart == null) {
			throw new Error("expected cart but didnt find one")
		}
		return cart
		// if (this.isCreatingOrFetchingCart === true) {
		// 	await new Promise((resolve) => {
		// 		setTimeout(resolve, 1000)
		// 	})
		// 	return this.fetchOrCreateCart()
		// }

		// this.isCreatingOrFetchingCart = true
		// if (cartId != null) {
		// 	return this.fetchCart({ cartId })
		// 		.then((cart) => {
		// 			this.isCreatingOrFetchingCart = false
		// 			return cart
		// 		})
		// 		.catch((_err) => {
		// 			localStorage.removeItem(this.CART_ID_KEY)
		// 			this.isCreatingOrFetchingCart = false
		// 			return this.fetchOrCreateCart()
		// 		})
		// }
		// const cartResponse = await this.createCart()
		// const cart = convertCartResponseCartToInitializedCart(cartResponse)
		// localStorage.setItem(this.CART_ID_KEY, cart.id)
		// this.isCreatingOrFetchingCart = false
		// return cart
	}

	async createCart() {
		const query = `
				#graphql
			mutation cartCreate {
				cartCreate {
					cart {
						${FRAGMENTS.cart}
					}
					userErrors {
						field
						message
					}
				}
			}
		`

		return this.client()
			.request<{
				cartCreate: {
					// todo - update me
					cart: App.InitializedCart
				}
			}>(query, {})
			.then((data) => {
				return data.cartCreate.cart
			})
	}

	async removeCartItem(opts: {
		variantId: string
		cart: {
			id: string
		}
	}): Promise<void> {
		const variables = {
			cartId: opts.cart.id,
			lineIds: [opts.variantId]
		}

		const query = `
				#graphqlmutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
			cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
				cart {
					${FRAGMENTS.cart}
				}
				userErrors {
					field
					message
				}
			}
		}`

		await this.client()
			.request<{
				cartLinesRemove: {
					cart: App.StorefrontAPI.GraphQLCart
				}
			}>(query, variables)
			.then((data) => {
				const cart = convertCartResponseCartToInitializedCart(
					data.cartLinesRemove.cart
				)
				useGlobalStore.setState({
					cart: {
						...cart
					}
				})
				// return this.fetchCartAndSetState({ cartId: opts.cart.id })
			})
			.catch((error) => {
				console.error(error)
			})
	}

	async updateCartItemQty(opts: {
		lineItemId: string
		quantity: number
		cart: {
			id: string
		}
	}): Promise<void> {
		const variables = {
			cartId: opts.cart.id,
			lines: [
				{
					id: opts.lineItemId,
					// "merchandiseId": "",
					quantity: opts.quantity
				}
			]
		}

		const query = `
				#graphqlmutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
			cartLinesUpdate(cartId: $cartId, lines: $lines) {
				cart {
					${FRAGMENTS.cart}
				}
				userErrors {
					field
					message
				}
			}
		}`

		await this.client()
			.request<{
				cartLinesUpdate: {
					cart: App.StorefrontAPI.GraphQLCart
				}
			}>(query, variables)
			.then((_data) => {
				return this.fetchCartAndSetState({ cartId: opts.cart.id })
			})
			.catch((error) => {
				console.error(error)
			})
	}

	async addCartItem(opts: {
		variant: {
			id: string
			availableForSale: boolean
		}
		quantity: number
		cart: {
			id: string
		}
	}): Promise<void> {
		if (opts.variant.availableForSale !== true) {
			console.error("opts.variant.availableForSale !== true")
			return
		}

		const variables = {
			cartId: opts.cart.id,
			lines: [
				{
					merchandiseId: opts.variant.id,
					quantity: opts.quantity
				}
			]
		}

		const query = `
				#graphql
		mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
			cartLinesAdd(cartId: $cartId, lines: $lines) {
			  cart {
					${FRAGMENTS.cart}
				}
				userErrors {
					field
					message
				}
			}
		}`

		await this.client()
			.request<{ cartLinesAdd: { cart: App.InitializedCart } }>(query, variables)
			.then((_res) => {
				return this.fetchCartAndSetState({ cartId: opts.cart.id })
			})
			.catch((error) => {
				console.error(error)
			})
	}
	async search(searchInput: string): Promise<App.StorefrontAPI.Product<false>[]> {
		const query = `#graphql
		{
			products(first: 5, query: "title:${searchInput}") {
				edges {
					node {
						id
						title
						publishedAt
						handle
						availableForSale
						options {
							name
							values
						}
						priceRange {
							minVariantPrice {
							amount
							currencyCode
							}
							maxVariantPrice {
							amount
							currencyCode
							}
						}
						images(first: 5) {
							nodes {
								url
								width
								height
								altText
							}
						}

						featuredImage {
							url
							altText
							width
							height
						}
						variants(first: 100) {
							nodes {
								id
								availableForSale
								image {
									url
									altText
									width
									height
								}
								price {
									amount
									currencyCode
								}
								compareAtPrice {
									amount
									currencyCode
								}
								selectedOptions {
									name
									value
								}
							}
						}
					}
			  	}
			}
		}`

		return this.client()
			.request<{
				products: {
					edges: Array<{
						node: App.StorefrontAPI.Product<true>
					}>
				}
			}>(query)
			.then((res) => {
				return res.products.edges.map((edges) => {
					return {
						...edges.node,
						media: edges.node.media?.nodes,
						variants: edges.node.variants.nodes,
						images: edges.node.images.nodes,
						other_colors_products: undefined
					}
				})
			})
			.catch((error) => {
				console.error(error)
				throw error
			})
	}

	fetchCartAndSetState(opts: { cartId: string }) {
		return this.fetchCart(opts).then((cart) => {
			useGlobalStore.setState({
				cart
			})
		})
	}

	async getProductRecommendation(args: {
		id: string
	}): Promise<App.StorefrontAPI.Product[]> {
		const query = `#graphql
		query {
  productRecommendations(productId:"${args.id}") {
		id
		title
		publishedAt
		handle
		availableForSale
		vendor
		options {
			name
			values
		}
		priceRange {
			minVariantPrice {
			amount
			currencyCode
			}
			maxVariantPrice {
			amount
			currencyCode
			}
		}
		images(first: 2) {
			nodes {
				url
				width
				height
				altText
			}
		}
		featuredImage {
			url
			altText
			width
			height
		}
		variants(first: 100) {
			nodes {
				availableForSale
				id
				selectedOptions {
					name
					value
				}

				image {
					url
					altText
					width
					height
				}
				price {
					amount
					currencyCode
				}
				compareAtPrice {
					amount
					currencyCode
				}
			}
		}
	}
}`

		return this.client()
			.request<{
				productRecommendations: App.StorefrontAPI.Product<true>[]
			}>(query)
			.then((res) => {
				const products = res.productRecommendations.map((product) => {
					return {
						...product,
						media: product.media?.nodes,
						images: product.images.nodes,
						variants: product.variants.nodes
					}
				})
				return products
			})
	}

	customer = {
		createAddress: async (args: {
			customerAccessToken: string
			address: Omit<App.StorefrontAPI.CustomerAddress, "id">
		}) => {
			const query = `#graphql
				mutation customerAddressCreate(
					$address: MailingAddressInput!
					$customerAccessToken: String!
				) {
					customerAddressCreate(
						address: $address
						customerAccessToken: $customerAccessToken
					) {

						customerAddress {
							address1
							address2
							city
							company
							country
							firstName
							lastName
							phone
							province
							zip
						}
						customerUserErrors {
							code
							field
							message
						}
					}
				}
			`

			return this.client()
				.request<{
					customerAddressCreate: {
						customerAddress: App.StorefrontAPI.CustomerAddress
					}
				}>(query, {
					address: args.address,
					customerAccessToken: args.customerAccessToken
				})
				.catch((err) => {
					console.error(err, "customer.accessTokenCreate")
					throw err
				})
		},
		updateAddress: async (args: {
			customerAccessToken: string
			address: Omit<App.StorefrontAPI.CustomerAddress, "id">
			addressId: string
		}) => {
			const query = `#graphql
				mutation customerAddressUpdate(
					$address: MailingAddressInput!
					$customerAccessToken: String!
					$id: ID!
				) {
					customerAddressUpdate(
						address: $address
						customerAccessToken: $customerAccessToken
						id: $id
					) {
						customerAddress {
							address1
							address2
							city
							company
							country
							firstName
							lastName
							phone
							province
							zip
						}
						customerUserErrors {
							code
							field
							message
						}
					}
				}
			`

			return this.client()
				.request<{
					customerAddressUpdate: {
						customerAddress: App.StorefrontAPI.CustomerAddress
					}
				}>(query, {
					address: args.address,
					customerAccessToken: args.customerAccessToken,
					id: args.addressId
				})
				.catch((err) => {
					console.error(err, "customer.accessTokenCreate")
					return null
				})
		},

		deleteAddress: async (args: { customerAccessToken: string; addressId: string }) => {
			const query = `#graphql
				mutation customerAddressDelete($customerAccessToken: String!, $id: ID!) {
					customerAddressDelete(customerAccessToken: $customerAccessToken, id: $id) {
						customerUserErrors {
							code
							field
							message
						}
						deletedCustomerAddressId
					}
				}
			`

			return this.client()
				.request<{
					customerAddressDelete: {
						deletedCustomerAddressId: string
					}
				}>(query, {
					customerAccessToken: args.customerAccessToken,
					id: args.addressId
				})
				.catch((err) => {
					console.error(err, "customer.accessTokenCreate")
					return null
				})
		},

		getCustomer: async (opts: { email: string; password: string }) => {
			const accessToken = await this.customer.accessTokenCreate({
				...opts
			})
			const query = `
				#graphql
				{
					customer(customerAccessToken: "${accessToken!.accessToken}") {
						id,
						email,
						firstName,
						lastName,
						numberOfOrders
					}
				}
			`

			return this.client()
				.request<{
					customer: App.StorefrontAPI.Customer
				}>(query, {})
				.then((res) => {
					return res.customer
				})
				.catch((err) => {
					console.error(err, "customer.accessTokenCreate")
					return null
				})
		},
		getCustomerByToken: async (token: string) => {
			const query = `#graphql
				{
					customer(customerAccessToken: "${token}") {
						id,
						email,
						firstName,
						lastName,
						numberOfOrders
					}
				}
			`

			return this.client()
				.request<{
					customer: App.StorefrontAPI.Customer
				}>(query, {})
				.then((res) => {
					return res.customer
				})
				.catch((err) => {
					console.error(err, "customer.accessTokenCreate")
					return null
				})
		},

		accessTokenCreate: (
			opts: Readonly<{
				email: string
				password: string
			}>
		): Promise<null | {
			accessToken: string
			expiresAt: string
		}> => {
			const query = `#graphql
				mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
					customerAccessTokenCreate(input: $input) {
						customerAccessToken {
							accessToken
							expiresAt
						}
						customerUserErrors {
							code
							field
							message
						}
					}
				}
			`
			return this.client()
				.request<{
					customerAccessTokenCreate: {
						customerAccessToken: {
							accessToken: string
							expiresAt: string
						}
					}
				}>(query, {
					input: opts
				})
				.then((res) => {
					localStorage.setItem(
						"token",
						res.customerAccessTokenCreate.customerAccessToken.accessToken
					)
					return res.customerAccessTokenCreate.customerAccessToken
				})
				.catch((err) => {
					console.error(err, "customer.accessTokenCreate")
					return null
				})
		},
		create: (args: {
			acceptsMarketing: boolean
			email: string
			firstName?: string
			lastName?: string
			password: string
			phone?: string
		}) => {
			const query = `#graphql
				mutation customerCreate($input: CustomerCreateInput!) {
					customerCreate(input: $input) {
						customer {
							id
						}
						customerUserErrors {
							code
							field
							message
						}
					}
				}
			`

			return this.client()
				.request<{
					customerCreate: {
						customer: {
							id: string
						}
					}
				}>(query, {
					input: {
						acceptsMarketing: args.acceptsMarketing,
						email: args.email,
						firstName: args.firstName,
						lastName: args.lastName,
						password: args.password,
						phone: args.phone
					}
				})
				.then((res) => {
					return res.customerCreate.customer
				})
				.catch((err) => {
					console.error(err, "customer.create")
				})
		}
	}

	collection = {
		queryWithProductFilters: async (args: {
			sortKey?: string
			reverse?: boolean
			filters: FiltersQueryParams
			collectionHandle: string
		}): Promise<undefined | App.StorefrontAPI.Collection<true>> => {
			return this.query({
				collection: [
					{
						handle: args.collectionHandle
					},
					{
						...COLLECTION_ROUTE_QUERY_REQ.collection,
						products: [
							{
								...COLLECTION_ROUTE_QUERY_REQ.collection.products[0],
								filters: args.filters
							},
							COLLECTION_ROUTE_QUERY_REQ.collection.products[1]
						]
					}
				]
			}).then((res) => res.data!.collection)
		},
		queryWithMetafields: async (args: {
			collectionHandle: string
		}): Promise<undefined | App.StorefrontAPI.Collection<true>> => {
			return this.query({
				collection: [
					{
						handle: args.collectionHandle
					},
					{
						...COLLECTION_ROUTE_QUERY_REQ.collection
					}
				]
			}).then((res) => res.data!.collection)
		}
	}
}

// export default StorefrontAPI
const storefrontAPI = new StorefrontAPI()
export { storefrontAPI }
