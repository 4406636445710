export const locales = {
	myAccount: "Konto",
	products: {
		product: {
			addToCartBtn: "Legg i handlekurv",
			soldoutBtn: "Utsolgt",
			description: "Beskrivelse",
			reviews: "vurderinger"
		},
		general: {
			sale: "Salg"
		}
	},
	slideoutCart: {
		addedToCart: "Lagt til i handlekurven",
		checkout: "Til kasse",
		total: "Total",
		shop: "Shop",
		cartEmpty: "Din handlekurv er tom."
	},
	addWidget: {
		addToCart: "Legg i handlekurv",
		soldOut: "Utsolgt",
		selectAllOptions: "Velg størrelse"
	},
	collections: {
		general: {
			items: "Produkter"
		},
		filter: {
			sort_and_filter: "Sorter &amp; Filtrer",
			clear_all: "Fjern filter"
		},
		sorting: {
			title: "Sorter"
		}
	},
	cart: {
		general: {
			empty: "Din handlekurv er tom.",
			title: "Min handlekurv",
			savings: "Du sparer"
		}
	},
	customer: {
		login: {
			title: "Logg inn"
		},
		register: {
			title: "Opprett konto"
		},
		account: {
			title: "Konto",
			hello: "Hallo",
			account_details: "kontodetaljer",
			name: "Navn",
			email: "E-post",
			my_orders: "Mine Bestillinger",
			make_your_first_order: "Gjør din første bestilling",
			you_have_not_placed_any_orders_yet:
				"Du har ikke lagt inn noen bestillinger ennå.",
			actions: "Handlinger",
			back_to_shop: "Tilbake til butikk",
			logout: "Logg ut",
			address_book: "Adressebok",
			add_new_address: "Legg til ny adresse",
			no_addresses_found_yet: "ingen adresse funnet ennå",
			address: "Adresse",
			delete: "Slett",
			edit: "Redigere",
			new_form: "ny form",
			edit_form: "rediger form",
			submit: "sende inn",
			addresses: "Adresser",
			dashboard: "Dashbord"
		},
		addresses: {
			first_name: "Fornavn",
			last_name: "Etternavn",
			company: "Bedrift",
			address1: "Adresse1",
			address2: "Adresse2",
			city: "By",
			country: "Land",
			phone: "Telefon",
			zip: "Postkode"
		},
		orders: {
			title: "Bestillingshistorikk",
			order_number: "Bestillingsnummer",
			date: "Dato",
			payment_status: "Betalingsstatus",
			total: "Total",
			none: "Du har ingen bestillinger enda.",
			fulfillment_status: "Oppfyllelsesstatus",
			details: "Detaljer"
		},
		order: {
			title: "Bestilling",
			date: "Dato",
			product: "Produkt",
			price: "Pris",
			quantity: "Lagerbeholdning",
			total: "Total",
			subtotal: "Delsum",
			shipping: "Shipping",
			tax: "Avgift"
		}
	},
	general: {
		search: {
			no_results_html: "Ingen resultater for",
			placeholder: "Søk",
			inline_search_box_title: "Ingen resultater for?",
			popular_searches: "Populære søk",
			categories: "Kategorier",
			products: "Produkter",
			view_results_for: "Vis alle resultater for",
			sort_by_price_low_to_high: "Pris: Lav til Høy",
			sort_by_price_high_to_low: "Pris: Høy til Lav",
			sort_by_newest: "Nyeste først",
			sort_by_oldest: "Eldste først",
			sort_by_popularity: "Populær",
			sort_by_reviews: "Kundeanmeldelser",
			sort_by_a_to_z: "Alfabetisk: A-Æ",
			sort_by_z_to_a: "Alfabetisk: Æ-A",
			sort_by_relevency: "Relevans",
			show_more: "Vis mer",
			show_less: "Vis mindre"
		}
	},
	custom: {
		colors: "Farger",
		you_might_also_like: "Andre kjøpte også",
		your_wishlist: "Ønskelisten din",
		have_any_questions: "Har du noen spørsmål?",
		nearest_stores: "Nærmeste butikker",
		all_stores: "Alle butikker",
		shipping: "Nettlager",
		change_store: "Bytt butikk",
		hide_out_of_stock: "Gjem butikker uten lager",
		select_location: "Velg butikk",
		my_store: "Min butikk",
		selected: "Valgt"
	}
}
