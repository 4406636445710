import { CSSProperties } from "react"

type Props = {
	style?: CSSProperties
}
const Spinner = (props: Props) => {
	return (
		<div
			className="spinner"
			style={{
				...props.style
			}}
		/>
	)
}

export default Spinner
