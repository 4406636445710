import SVGIcon from "./ui/SVGIcon"
import Swiper from "swiper"

import { CSSProperties, useEffect, useRef } from "react"
// import "swiper/css/navigation"

type Props = {
	swiperOptions?: {
		loop?: boolean
		slidesPerViewDesktop?: number
		slidesPerViewMobile?: number
		autoHeight?: boolean
		spaceBetween?: number
	}
	className?: string
	style?: CSSProperties
	id?: string
	children: JSX.Element[]
	renderNavigation?: boolean
	navigationClassName?: string
	renderPagination?: boolean
	paginationClassName?: string
	apiRef?: (swiper: Swiper) => void
}
const SwiperWrapper = (props: Props) => {
	const swiperRef = useRef<null | Swiper>(null)
	const containerRef = useRef<null | HTMLDivElement>(null)
	const paginationRef = useRef<null | HTMLDivElement>(null)

	const { apiRef, swiperOptions = {}, renderPagination } = props

	useEffect(() => {
		const slidePerViewDesktop = swiperOptions.slidesPerViewDesktop || 1
		const slidesPerViewMobile = swiperOptions.slidesPerViewMobile || slidePerViewDesktop
		const swiperElem = containerRef.current!
		swiperRef.current = new Swiper(swiperElem, {
			modules: [],
			spaceBetween:
				swiperOptions.spaceBetween != null ? swiperOptions.spaceBetween : 10,
			autoHeight: swiperOptions.autoHeight,
			loop: swiperOptions.loop,
			slidesPerView: slidesPerViewMobile,
			breakpoints: {
				1024: {
					slidesPerView: slidePerViewDesktop
				}
			}
			// NOTE: if you set loop to true, make sure you account for the click event listeners where applicable
			// loop: true
		})

		if (apiRef != null) {
			apiRef(swiperRef.current)
		}

		{
			renderPagination === true &&
				swiperRef.current!.on("slideChange", (e: { activeIndex: number }) => {
					paginationRef.current?.childNodes.forEach((child, i) => {
						if (!(child instanceof HTMLElement)) {
							return
						}
						child.style.opacity = "0.4"
						if (i === e.activeIndex) {
							child.style.opacity = "1"
						}
					})
				})

			return () => {
				swiperRef.current!.destroy(true)
			}
		}
	}, [apiRef, renderPagination, swiperOptions])

	return (
		<div
			id={props.id}
			ref={containerRef}
			style={{
				...props.style
			}}
			className={`swiper ${props.className}`}>
			{/* <!-- Additional required wrapper --> */}
			<div className="swiper-wrapper">
				{/* <!-- Slides --> */}
				{props.children.map((child, i) => {
					return (
						<div
							key={i}
							className="swiper-slide">
							{child}
						</div>
					)
				})}
			</div>

			{props.children.length > 1 && (
				<>
					{props.renderNavigation === true && (
						<>
							<div
								onClick={() => {
									swiperRef.current!.slidePrev()
								}}
								style={{
									left: 5,
									zIndex: 5,
									top: "50%",
									cursor: "pointer",
									borderRadius: "100%",
									position: "absolute",
									backgroundColor: "white",
									border: "1px solid #f1f1f1",
									transform: "translateY(-50%)",
									boxShadow: "1px 1px 6px #0000000d"
								}}
								className={`swiper-button-prev ${props.navigationClassName}`}>
								<SVGIcon
									name="chevron-left"
									color="var(--color-cta)"
								/>
							</div>
							<div
								onClick={() => {
									swiperRef.current!.slideNext()
								}}
								style={{
									right: 5,
									zIndex: 5,
									top: "50%",
									cursor: "pointer",
									position: "absolute",
									borderRadius: "100%",
									backgroundColor: "white",
									border: "1px solid #f1f1f1",
									transform: "translateY(-50%)",
									boxShadow: "1px 1px 6px #0000000d"
								}}
								className={`swiper-button-next ${props.navigationClassName}`}>
								<SVGIcon
									name="chevron-right"
									color="var(--color-cta)"
								/>
							</div>
						</>
					)}
					{props.renderPagination === true && (
						<div
							ref={paginationRef}
							className={`space-x-2 ${props.paginationClassName}`}
							style={{
								zIndex: 5,
								bottom: 0,
								left: "50%",
								position: "absolute",
								transform: "translateX(-50%)"
							}}>
							{props.children.map((_child, i) => {
								return (
									<span
										key={i}
										className="cursor-pointer"
										onClick={() => {
											swiperRef.current!.slideTo(i)
										}}
										style={{
											opacity: i === 0 ? 1 : 0.4,
											display: "inline-block",
											width: 6,
											height: 6,
											borderRadius: "100%",
											backgroundColor: "red"
										}}
									/>
								)
							})}
						</div>
					)}
				</>
			)}
		</div>
	)
}

export default SwiperWrapper
