export const menus = {
   "activeSegmentMenuTitle": "Dame",
   "menu": {
      "title": "Menu",
      "children": [
         {
            "title": "Dame",
            "url": "/",
            "type": "frontpage_link",
            "active": true,
            "child_active": false,
            "children": [
               {
                  "title": "Nyheter",
                  "url": "/collections/nyheter-dame",
                  "type": "collection_link",
                  "active": false,
                  "child_active": false
               },
               {
                  "title": "Kategorier",
                  "url": "/collections/nyheter-dame",
                  "type": "collection_link",
                  "active": false,
                  "child_active": false,
                  "children": [
                     {
                        "title": "Alle produkter",
                        "url": "/collections/nyheter-dame",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Blazere",
                        "url": "/collections/blazer-dame",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Bluser",
                        "url": "/collections/dame-bluser",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Bukser",
                        "url": "/collections/dame-bukser",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Cardigan",
                        "url": "/collections/dame-cardigan",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Gensere",
                        "url": "/collections/dame-genser",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Kjoler",
                        "url": "/collections/dame-kjoler",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Shorts",
                        "url": "/collections/dame-shorts",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Skjorter",
                        "url": "/collections/dame-skjorter",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Sko",
                        "url": "/collections/dame-sko",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Skjørt",
                        "url": "/collections/dame-skjort",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Topper",
                        "url": "/collections/dame-topper",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "T-Skjorter",
                        "url": "/collections/dame-t-skjorte",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Yttertøy",
                        "url": "/collections/dame-yttertoy",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Tilbehør",
                        "url": "/collections/dame-tilbehor",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Livsstil",
                        "url": "/collections/nyheter-livsstil",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     }
                  ]
               },
               {
                  "title": "Merker",
                  "url": "/pages/merker",
                  "type": "page_link",
                  "active": false,
                  "child_active": false,
                  "children": [
                     {
                        "title": "American Dreams",
                        "url": "/collections/american-dreams",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "A-view",
                        "url": "/collections/a-view",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "ByDreams",
                        "url": "/collections/bydreams",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Candles with a twist",
                        "url": "/collections/candles-with-a-twist",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Chimi",
                        "url": "/collections/chimi",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Cras",
                        "url": "/collections/cras",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Golightly",
                        "url": "/collections/golightly",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Hultquist",
                        "url": "/collections/hultquist",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "HVISK",
                        "url": "/collections/hvisk",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Hype the detail",
                        "url": "/collections/hype-the-detail",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Iben",
                        "url": "/collections/iben",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Ivy Copenhagen",
                        "url": "/collections/ivy-copenhagen",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Just Female",
                        "url": "/collections/just-female",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Love Lolita",
                        "url": "/collections/love-lolita",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "MbyM / Message",
                        "url": "/collections/mbym-message",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "MessyWeekend",
                        "url": "/collections/messyweekend",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "NCLA Beauty",
                        "url": "/collections/ncla-beauty",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "New Mags",
                        "url": "/collections/new-mags",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Object",
                        "url": "/collections/object",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Pavement",
                        "url": "/collections/pavement",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Rains",
                        "url": "/collections/rains",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Ravn Ravn",
                        "url": "/collections/ravn-ravn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "RUNAWAY",
                        "url": "/collections/runaway",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Santi",
                        "url": "/collections/santi",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Selected Femme",
                        "url": "/collections/selected-femme",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Stine Goya",
                        "url": "/collections/stine-goya",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Urban Pioneers",
                        "url": "/collections/urban-pioneers",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "YAS",
                        "url": "/collections/yas",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Who Is She",
                        "url": "/collections/who-is-she",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     }
                  ]
               },
               {
                  "title": "Salg",
                  "url": "/collections/damekupp",
                  "type": "collection_link",
                  "active": false,
                  "child_active": false
               },
               {
                  "title": "Om oss",
                  "url": "/blogs/om-oss/om-oss",
                  "type": "article_link",
                  "active": false,
                  "child_active": false
               }
            ]
         },
         {
            "title": "Herre",
            "url": "/pages/herre",
            "type": "page_link",
            "active": false,
            "child_active": false,
            "children": [
               {
                  "title": "Nyheter",
                  "url": "/collections/nyheter-herre",
                  "type": "collection_link",
                  "active": false,
                  "child_active": false
               },
               {
                  "title": "Kategorier",
                  "url": "/collections/herre-alle",
                  "type": "collection_link",
                  "active": false,
                  "child_active": false,
                  "children": [
                     {
                        "title": "Blazer",
                        "url": "/collections/herre-blazer",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Bukser",
                        "url": "/collections/herre-bukser",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Gensere",
                        "url": "/collections/herre-genser",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Shorts",
                        "url": "/collections/herre-shorts",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Skjorter",
                        "url": "/collections/herre-skjorter",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Sko",
                        "url": "/collections/herre-sko",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Tilbehør",
                        "url": "/collections/herre-tilbehor",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "T-skjorter",
                        "url": "/collections/herre-t-skjorte",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Yttertøy",
                        "url": "/collections/herre-yttertoy",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     }
                  ]
               },
               {
                  "title": "Merker",
                  "url": "/pages/merker",
                  "type": "page_link",
                  "active": false,
                  "child_active": false
               },
               {
                  "title": "Salg",
                  "url": "/collections/herrekupp",
                  "type": "collection_link",
                  "active": false,
                  "child_active": false
               },
               {
                  "title": "Om oss",
                  "url": "/blogs/om-oss/om-oss",
                  "type": "article_link",
                  "active": false,
                  "child_active": false
               }
            ]
         },
         {
            "title": "Barn",
            "url": "/pages/barn",
            "type": "page_link",
            "active": false,
            "child_active": false,
            "children": [
               {
                  "title": "Baby (56 -80)",
                  "url": "/collections/barn-baby-alle-produkter",
                  "type": "collection_link",
                  "active": false,
                  "child_active": false,
                  "children": [
                     {
                        "title": "Nyheter",
                        "url": "/collections/barn-baby-alle-produkter",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Bestselger",
                        "url": "/collections/baby-bestselgere",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Overdeler",
                        "url": "/collections/barn-baby-overdeler",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Pentøy",
                        "url": "/collections/barn-baby-pentoy",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Pysj",
                        "url": "/collections/pysj-baby",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Ull",
                        "url": "/collections/barn-baby-ull",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     }
                  ]
               },
               {
                  "title": "Mini (86 - 110)",
                  "url": "/collections/barn-mini-alle-produkter",
                  "type": "collection_link",
                  "active": false,
                  "child_active": false,
                  "children": [
                     {
                        "title": "Nyheter",
                        "url": "/collections/barn-mini-alle-produkter",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Overdeler",
                        "url": "/collections/barn-mini-overdeler",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Kjoler",
                        "url": "/collections/barn-mini-kjoler",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Pentøy",
                        "url": "/collections/barn-mini-pentoy",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Pysj",
                        "url": "/collections/pysj-mini",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Shorts",
                        "url": "/collections/barn-mini-skjorts",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "T-skjorter",
                        "url": "/collections/barn-mini-t-skjorter",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Tilbehør",
                        "url": "/collections/barn-mini-tilbehor",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Ull",
                        "url": "/collections/barn-mini-ull",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Underdeler",
                        "url": "/collections/barn-mini-underdeler",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Yttertøy",
                        "url": "/collections/barn-yttertoy-mini",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     }
                  ]
               },
               {
                  "title": "Kids (116 - 164)",
                  "url": "/collections/barn-kids-alle-produkter",
                  "type": "collection_link",
                  "active": false,
                  "child_active": false,
                  "children": [
                     {
                        "title": "Nyheter",
                        "url": "/collections/barn-kids-alle-produkter",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Overdeler",
                        "url": "/collections/barn-kids-overdeler",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Pentøy",
                        "url": "/collections/barn-kids-pentoy",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Shorts",
                        "url": "/collections/barn-kids-skjorts",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "T-skjorter",
                        "url": "/collections/barn-kids-t-skjorte",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Tilbehør",
                        "url": "/collections/barn-kids-tilbehor",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Ull",
                        "url": "/collections/barn-kids-ull",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Underdeler",
                        "url": "/collections/barn-kids-underdeler",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Yttertøy",
                        "url": "/collections/barn-kids-yttertoy",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     }
                  ]
               },
               {
                  "title": "Tilbehør",
                  "url": "/collections/barn-tilbehor",
                  "type": "collection_link",
                  "active": false,
                  "child_active": false
               },
               {
                  "title": "Merker",
                  "url": "/pages/merker",
                  "type": "page_link",
                  "active": false,
                  "child_active": false,
                  "children": [
                     {
                        "title": "Bibs",
                        "url": "/collections/bibs-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Creamie",
                        "url": "/collections/creamie-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Elodie",
                        "url": "/collections/elodie",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Filiokus",
                        "url": "/collections/filiokus-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Fixoni",
                        "url": "/collections/fixoni-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Hummel",
                        "url": "/collections/hummel-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Hust & Claire",
                        "url": "/collections/hust-claire-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Jack&Jones Junior",
                        "url": "/collections/jack-jones-junior-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Kattnakken",
                        "url": "/collections/kattnakken-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Kids Only",
                        "url": "/collections/kids-only-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Levis",
                        "url": "/collections/levis-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Lil' Atelier",
                        "url": "/collections/lil-atelier-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "LMTD",
                        "url": "/collections/lmtd-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Lumimi",
                        "url": "/collections/lumimi-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Lykkeland",
                        "url": "/collections/lykkeland-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Minymo",
                        "url": "/collections/minymo-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "MP",
                        "url": "/collections/mp",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "NAME IT",
                        "url": "/collections/name-it-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Nicki & Ellis",
                        "url": "/collections/nicki-ellis-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "På Stell",
                        "url": "/collections/pa-stell-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Papirkompaniet",
                        "url": "/collections/papirkompaniet-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Reima",
                        "url": "/collections/reima-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Sebra",
                        "url": "/collections/sebra-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Sometime Soon",
                        "url": "/collections/sometime-soon-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "VILJE&VE",
                        "url": "/collections/vilje-ve-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     }
                  ]
               },
               {
                  "title": "Salg",
                  "url": "/collections/salg-barn",
                  "type": "collection_link",
                  "active": false,
                  "child_active": false
               }
            ]
         }
      ]
   },
   "mobileMenu": {
      "title": "Mobile Menu",
      "children": [
         {
            "title": "Dame",
            "url": "/",
            "type": "frontpage_link",
            "active": true,
            "child_active": true,
            "children": [
               {
                  "title": "Dame forside",
                  "url": "/",
                  "type": "frontpage_link",
                  "active": true,
                  "child_active": false
               },
               {
                  "title": "Nyheter",
                  "url": "/collections/nyheter-dame",
                  "type": "collection_link",
                  "active": false,
                  "child_active": false,
                  "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/files/Nyheter.png?v=1654355058"
               },
               {
                  "title": "Kategorier",
                  "url": "/collections/dame-alle-produkter",
                  "type": "collection_link",
                  "active": false,
                  "child_active": false,
                  "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/files/Kategorier.png?v=1654355163",
                  "children": [
                     {
                        "title": "Alle Produkter",
                        "url": "/collections/nyheter-dame",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/files/Nyheter.png?v=1654355058"
                     },
                     {
                        "title": "Blazere",
                        "url": "/collections/blazer-dame",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/SELECTED-FEM_SpringSummer2023_4202597_16089702_3.jpg?v=1675253963"
                     },
                     {
                        "title": "Bluser",
                        "url": "/collections/dame-bluser",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/AV3987-999Black-Main.jpg?v=1673535576"
                     },
                     {
                        "title": "Bukser",
                        "url": "/collections/dame-bukser",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/SELECTED-FEM_SpringSummer2023_4202585_16089701.jpg?v=1675253840"
                     },
                     {
                        "title": "Cardigan",
                        "url": "/collections/dame-cardigan",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/image_97dd994b-ab44-4e09-b1fe-24298a5d2dc5.jpg?v=1675432545"
                     },
                     {
                        "title": "Gensere",
                        "url": "/collections/dame-genser",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/5307760_71104_1881_packfront_1.jpg?v=1664366622"
                     },
                     {
                        "title": "Kjoler",
                        "url": "/collections/dame-kjoler",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/26027162_JellyBean_001.jpg?v=1648126302"
                     },
                     {
                        "title": "Shorts",
                        "url": "/collections/dame-shorts",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/Melinda_Shorts-Shorts-SG4216-3013_Pineapple_Picnic-2_1080x_a19996f7-8f5e-4281-bfdd-c38184a68959.webp?v=1656069587"
                     },
                     {
                        "title": "Skjorter",
                        "url": "/collections/dame-skjorter",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/AV1841_SonjaShirt_Yellow.jpg?v=1675435670"
                     },
                     {
                        "title": "Sko",
                        "url": "/collections/dame-sko",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/c4a9722b1387bebeff0d5621ac84e956.webp?v=1672666494"
                     },
                     {
                        "title": "Skjørt",
                        "url": "/collections/dame-skjort",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/sel__4306767__front1.jpg?v=1674464738"
                     },
                     {
                        "title": "Topper",
                        "url": "/collections/dame-topper",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/sel__4075897__front1.jpg?v=1674035143"
                     },
                     {
                        "title": "T-Skjorter",
                        "url": "/collections/dame-t-skjorte",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/sel__4166236__front.jpg?v=1674601850"
                     },
                     {
                        "title": "Yttertøy",
                        "url": "/collections/dame-yttertoy",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/IBEN_SS23_WaveBomber_Black_front_grey-scaled.jpg?v=1675434318"
                     },
                     {
                        "title": "Tilbehør",
                        "url": "/collections/dame-tilbehor",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/WHOISSHE_SS23--25_2000x_d23d381e-66a6-45e0-8259-35cac7400c34.jpg?v=1675428634"
                     },
                     {
                        "title": "Livsstil",
                        "url": "/collections/nyheter-livsstil",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/ManchetBrass-Pack_374c7b5d-bf3b-4a76-8659-90e6630085a6.jpg?v=1653114031"
                     }
                  ]
               },
               {
                  "title": "Merker",
                  "url": "/pages/merker",
                  "type": "page_link",
                  "active": false,
                  "child_active": false,
                  "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/files/Merker.png?v=1654354745",
                  "children": [
                     {
                        "title": "American Dreams",
                        "url": "/collections/american-dreams",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/AD1000-NeonPink-Main.jpg?v=1673459775"
                     },
                     {
                        "title": "A-view",
                        "url": "/collections/a-view",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/AV1841_SonjaShirt_Yellow.jpg?v=1675435670"
                     },
                     {
                        "title": "ByDreams",
                        "url": "/collections/bydreams",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/black_dark-grey-fio8na-long.webp?v=1661887788"
                     },
                     {
                        "title": "Candles with a twist",
                        "url": "/collections/candles-with-a-twist",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/unnamedlys.jpg?v=1648730851"
                     },
                     {
                        "title": "Chimi",
                        "url": "/collections/chimi",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/929_d42e669108-10168-111-1-full.webp?v=1655724341"
                     },
                     {
                        "title": "Cras",
                        "url": "/collections/cras",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/AstacrasBag_Pink.jpg?v=1674087780"
                     },
                     {
                        "title": "Golightly",
                        "url": "/collections/golightly",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/FN_4485_1024x1024_2x_5dbc0642-8f4c-4c48-b782-bf55fcd4e3c2.webp?v=1671049018"
                     },
                     {
                        "title": "Hultquist",
                        "url": "/collections/hultquist",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/S05041G_1512x_48265a0e-7872-423d-b62e-f2f871e70ba6.webp?v=1672660864"
                     },
                     {
                        "title": "HVISK",
                        "url": "/collections/hvisk",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/CAYMAN_POCKET_STRUCTURE-Crossbody-H2951-305_Sepia_Brown_2048x2048_3bd1e563-0236-470d-8e49-7b82f104c5e1.jpg?v=1675250670"
                     },
                     {
                        "title": "Hype the detail",
                        "url": "/collections/hype-the-detail",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/3-16665-77-4201_kopi.webp?v=1672220747"
                     },
                     {
                        "title": "Iben",
                        "url": "/collections/iben",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/IBEN_SS23_WaveBomber_Black_front_grey-scaled.jpg?v=1675434318"
                     },
                     {
                        "title": "Ivy Copenhagen",
                        "url": "/collections/ivy-copenhagen",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/IVY-Tara_Jeans_Wash_Cool_Barcelona-Jeans_Pants-I234498-51_Denim_blue.jpg?v=1674569259"
                     },
                     {
                        "title": "Just Female",
                        "url": "/collections/just-female",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/Oceanshirtblajustfemale1.jpg?v=1657788494"
                     },
                     {
                        "title": "Love Lolita",
                        "url": "/collections/love-lolita",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/GigiHollanders-114_1512x_e5f553a9-9000-4b78-aeac-98c455cce202.webp?v=1661514639"
                     },
                     {
                        "title": "MbyM / Message",
                        "url": "/collections/mbym-message",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/50647120-N74.jpg?v=1675179250"
                     },
                     {
                        "title": "MessyWeekend",
                        "url": "/collections/messyweekend",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/BILLE_CRYSTAL_PERSPECTIVE_NEW_1680x_2x.progressive_7a4c9930-28f4-4dd4-bb48-1481f4b9f685.webp?v=1654777693"
                     },
                     {
                        "title": "NCLA Beauty",
                        "url": "/collections/ncla-beauty",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/97b2ff22-8988-48b1-929d-b18faa2540c9_1400x_5a102119-4e50-4b2d-8ef9-44fef5c4e93f.jpg?v=1644323716"
                     },
                     {
                        "title": "New Mags",
                        "url": "/collections/new-mags",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/61OrNPktmRL-1-scaled.jpg?v=1619527689"
                     },
                     {
                        "title": "Object",
                        "url": "/collections/object",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/OBJECT_SpringSummer_3910058_23039103.jpg?v=1653044712"
                     },
                     {
                        "title": "Pavement",
                        "url": "/collections/pavement",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/c4a9722b1387bebeff0d5621ac84e956.webp?v=1672666494"
                     },
                     {
                        "title": "Rains",
                        "url": "/collections/rains",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Ravn Ravn",
                        "url": "/collections/ravn-ravn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/LITA_LINEN_DRESS-Dress-RC2603-001_Black.jpg?v=1673352321"
                     },
                     {
                        "title": "RUNAWAY",
                        "url": "/collections/runaway",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/IMG_2888_4070281a-844e-44ca-9ed8-12c7716bcb2a.jpg?v=1662114390"
                     },
                     {
                        "title": "Santi",
                        "url": "/collections/santi",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/image00001_1.jpg?v=1653092495"
                     },
                     {
                        "title": "Selected Femme",
                        "url": "/collections/selected-femme",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/SELECTED-FEM_SpringSummer2023_4202597_16089702_3.jpg?v=1675253963"
                     },
                     {
                        "title": "Stine Goya",
                        "url": "/collections/stine-goya",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/1024x1024-905132af47a541068ff823392d476da8.jpg?v=1670429426"
                     },
                     {
                        "title": "Urban Pioneers",
                        "url": "/collections/urban-pioneers",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/WEB_Image_Nicke_Dress_Green_S_Satin_gathering_dres_50273_nicke_green_1702545462_plid_20116.jpg?v=1669990241"
                     },
                     {
                        "title": "YAS",
                        "url": "/collections/yas",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/26027162_JellyBean_001.jpg?v=1648126302"
                     },
                     {
                        "title": "Who Is She",
                        "url": "/collections/who-is-she",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/WHOISSHE_SS23--25_2000x_d23d381e-66a6-45e0-8259-35cac7400c34.jpg?v=1675428634"
                     }
                  ]
               },
               {
                  "title": "Salg",
                  "url": "/collections/damekupp",
                  "type": "collection_link",
                  "active": false,
                  "child_active": false,
                  "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/files/Salg.png?v=1654354902"
               },
               {
                  "title": "Om oss",
                  "url": "/blogs/om-oss/om-oss",
                  "type": "article_link",
                  "active": false,
                  "child_active": false
               }
            ]
         },
         {
            "title": "Herre",
            "url": "/pages/herre",
            "type": "page_link",
            "active": false,
            "child_active": false,
            "children": [
               {
                  "title": "Nyheter",
                  "url": "/collections/nyheter-herre",
                  "type": "collection_link",
                  "active": false,
                  "child_active": false,
                  "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/files/Nyheter.png?v=1654355058"
               },
               {
                  "title": "Kategorier",
                  "url": "/collections/herre-alle",
                  "type": "collection_link",
                  "active": false,
                  "child_active": false,
                  "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/files/Kategorier.png?v=1654355163",
                  "children": [
                     {
                        "title": "Blazere",
                        "url": "/collections/herre-blazer",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/sel__4054507__front.jpg?v=1672442354"
                     },
                     {
                        "title": "Bukser",
                        "url": "/collections/herre-bukser",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/abd077c8-8ed7-4bfe-8294-92ee3c3e49be.jpg?v=1675250888"
                     },
                     {
                        "title": "Gensere",
                        "url": "/collections/herre-genser",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/sel__3997596__front1.jpg?v=1671038083"
                     },
                     {
                        "title": "Shorts",
                        "url": "/collections/herre-shorts",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/Barcelona_Bomuld_Hoer_Shorts-Shorts-CC1860-Navy_900x_c555ffbf-f044-4509-902e-30a2fa244fb6.webp?v=1657108939"
                     },
                     {
                        "title": "Skjorter",
                        "url": "/collections/herre-skjorter",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/sel__3574928__front1.jpg?v=1675424610"
                     },
                     {
                        "title": "Sko",
                        "url": "/collections/herre-sko",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/WEB_Image6535013c_o519351123.jpg?v=1671118325"
                     },
                     {
                        "title": "Tilbehør",
                        "url": "/collections/herre-tilbehor",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/50055685_DarkGreyMelange_00U188_003_ProductLarge.jpg?v=1671223482"
                     },
                     {
                        "title": "T-skjorter",
                        "url": "/collections/herre-t-skjorte",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/202370_002_1_1946x_2d852bcd-d7cd-44d9-a1cd-eaa33f31c2cd.jpg?v=1674471929"
                     },
                     {
                        "title": "Yttertøy",
                        "url": "/collections/herre-yttertoy",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/HughFleeceJacket-OffWhite_FMS2179-Black_20-4.jpg?v=1675432723"
                     }
                  ]
               },
               {
                  "title": "Merker",
                  "url": "/pages/merker",
                  "type": "page_link",
                  "active": false,
                  "child_active": false,
                  "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/files/Merker.png?v=1654354745"
               },
               {
                  "title": "Salg",
                  "url": "/collections/herrekupp",
                  "type": "collection_link",
                  "active": false,
                  "child_active": false,
                  "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/files/Salg.png?v=1654354902"
               },
               {
                  "title": "Om oss",
                  "url": "/blogs/om-oss/om-oss",
                  "type": "article_link",
                  "active": false,
                  "child_active": false
               }
            ]
         },
         {
            "title": "Barn",
            "url": "/pages/barn",
            "type": "page_link",
            "active": false,
            "child_active": false,
            "children": [
               {
                  "title": "Salg",
                  "url": "/collections/salg-barn",
                  "type": "collection_link",
                  "active": false,
                  "child_active": false,
                  "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/files/Salg_3.png?v=1672835820"
               },
               {
                  "title": "Baby (56 - 80)",
                  "url": "/collections/barn-baby-alle-produkter",
                  "type": "collection_link",
                  "active": false,
                  "child_active": false,
                  "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/NAME-IT_Springsummer_4190199_13217166_1.jpg?v=1675424966",
                  "children": [
                     {
                        "title": "Nyheter",
                        "url": "/collections/barn-baby-alle-produkter",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/NAME-IT_Springsummer_4190199_13217166_1.jpg?v=1675424966"
                     },
                     {
                        "title": "Bestselgere",
                        "url": "/collections/baby-bestselgere",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/NAME-IT_Springsummer_4190199_13217166_1.jpg?v=1675424966"
                     },
                     {
                        "title": "Overdeler",
                        "url": "/collections/barn-baby-overdeler",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/NAME-IT_Springsummer_4153059_13215265.jpg?v=1675424650"
                     },
                     {
                        "title": "Pentøy",
                        "url": "/collections/barn-baby-pentoy",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/NAME-IT_AutumnWinter2022_3976553_13199225.jpg?v=1670602598"
                     },
                     {
                        "title": "Pysj",
                        "url": "/collections/pysj-baby",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/13189057_PaleMauve_003_ProductLarge.jpg?v=1653034769"
                     },
                     {
                        "title": "Ull",
                        "url": "/collections/barn-baby-ull",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/NAME-IT_AutumnWinter2022_3958300_13199104.jpg?v=1670602525"
                     },
                     {
                        "title": "Underdeler",
                        "url": "/collections/barn-baby-underdeler",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/nit__3947474__front.jpg?v=1674775490"
                     },
                     {
                        "title": "Yttertøy",
                        "url": "/collections/barn-baby-yttertoy",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/nit__3860478__front.jpg?v=1661552707"
                     },
                     {
                        "title": "Tilbehør",
                        "url": "/collections/barn-baby-tilbehor",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/NAME-IT_Springsummer_4190199_13217166_1.jpg?v=1675424966"
                     }
                  ]
               },
               {
                  "title": "Mini (86 - 110)",
                  "url": "/collections/barn-mini-alle-produkter",
                  "type": "collection_link",
                  "active": false,
                  "child_active": false,
                  "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/NAME-IT_Springsummer_4153986_13215333.jpg?v=1675419886",
                  "children": [
                     {
                        "title": "Nyheter",
                        "url": "/collections/barn-mini-alle-produkter",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/NAME-IT_Springsummer_4153986_13215333.jpg?v=1675419886"
                     },
                     {
                        "title": "Overdeler",
                        "url": "/collections/barn-mini-overdeler",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/nit__4148146__front.jpg?v=1674087688"
                     },
                     {
                        "title": "Kjoler",
                        "url": "/collections/barn-mini-kjoler",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false
                     },
                     {
                        "title": "Pentøy",
                        "url": "/collections/barn-mini-pentoy",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/k.jpg?v=1668590421"
                     },
                     {
                        "title": "Pysj",
                        "url": "/collections/pysj-mini",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/3706268_13194934.jpg?v=1653157980"
                     },
                     {
                        "title": "Shorts",
                        "url": "/collections/barn-mini-skjorts",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/214606-5065_2.jpg?v=1670592056"
                     },
                     {
                        "title": "T-skjorter",
                        "url": "/collections/barn-mini-t-skjorter",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/baby-mini-boy-anker-hc-t-skjorte_1200w_1.jpg?v=1675179277"
                     },
                     {
                        "title": "Tilbehør",
                        "url": "/collections/barn-mini-tilbehor",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/NAME-IT_Autumnwinter_4065602_13211596.jpg?v=1674035936"
                     },
                     {
                        "title": "Ull",
                        "url": "/collections/barn-mini-ull",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/NAME-IT_AutumnWinter2022_3958360_13199281.jpg?v=1674829172"
                     },
                     {
                        "title": "Underdeler",
                        "url": "/collections/barn-mini-underdeler",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/NAME-IT_Springsummer_4154111_13215358.jpg?v=1675419658"
                     },
                     {
                        "title": "Yttertøy",
                        "url": "/collections/barn-yttertoy-mini",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/nit__4097856__front.jpg?v=1674775428"
                     }
                  ]
               },
               {
                  "title": "Kids (116 - 164)",
                  "url": "/collections/barn-kids-alle-produkter",
                  "type": "collection_link",
                  "active": false,
                  "child_active": false,
                  "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/NAME-IT_Springsummer_4154549_13215714.jpg?v=1675418668",
                  "children": [
                     {
                        "title": "Nyheter",
                        "url": "/collections/barn-kids-alle-produkter",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/NAME-IT_Springsummer_4154549_13215714.jpg?v=1675418668"
                     },
                     {
                        "title": "Overdeler",
                        "url": "/collections/barn-kids-overdeler",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/jj__4158539__front1.jpg?v=1672407424"
                     },
                     {
                        "title": "Pentøy",
                        "url": "/collections/barn-kids-pentoy",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/jj__4088584__front.jpg?v=1667963800"
                     },
                     {
                        "title": "Shorts",
                        "url": "/collections/barn-kids-skjorts",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/214682-7120_2.jpg?v=1670591961"
                     },
                     {
                        "title": "T-skjorter",
                        "url": "/collections/barn-kids-t-skjorte",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/466631_217623-9806_A.jpg?v=1674829183"
                     },
                     {
                        "title": "Tilbehør",
                        "url": "/collections/barn-kids-tilbehor",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/NAME-IT_Autumnwinter_4065603_13211596.jpg?v=1674044049"
                     },
                     {
                        "title": "Ull",
                        "url": "/collections/barn-kids-ull",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/NAME-IT_AutumnWinter2022_3958673_13199436.jpg?v=1670602515"
                     },
                     {
                        "title": "Underdeler",
                        "url": "/collections/barn-kids-underdeler",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/NAME-IT_Springsummer_4154549_13215714.jpg?v=1675418668"
                     },
                     {
                        "title": "Yttertøy",
                        "url": "/collections/barn-kids-yttertoy",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/nit__4215749__front.jpg?v=1674547802"
                     }
                  ]
               },
               {
                  "title": "Tilbehør",
                  "url": "/collections/barn-tilbehor",
                  "type": "collection_link",
                  "active": false,
                  "child_active": false,
                  "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/psp_35_52cae794-a3e3-4ea2-ad59-159e3ef52efa.jpg?v=1653171760"
               },
               {
                  "title": "Merker",
                  "url": "/pages/merker",
                  "type": "page_link",
                  "active": false,
                  "child_active": false,
                  "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/files/Merker.png?v=1654354745",
                  "children": [
                     {
                        "title": "Bibs",
                        "url": "/collections/bibs-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/91341_5713795222872_BIBS_TRY-IT_COLLECTION_Blush_2_1000x1000_1080x_e107db8d-352b-477e-b549-71e1480935e1.jpg?v=1675179256"
                     },
                     {
                        "title": "Creamie",
                        "url": "/collections/creamie-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/pal_ece894d5-0db9-4cc4-b3f2-fe2860cd2b59.jpg?v=1668613255"
                     },
                     {
                        "title": "Elodie",
                        "url": "/collections/elodie",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/50570102598D-Autumn-Beanie-Nordic-Woodland-Front-AW22-PP.jpg?v=1671219532"
                     },
                     {
                        "title": "Filiokus",
                        "url": "/collections/filiokus-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/image_366132b4-d8c7-4405-83b3-d96fe8e615fd.jpg?v=1667891332"
                     },
                     {
                        "title": "Fixoni",
                        "url": "/collections/fixoni-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/422349-7871-1.webp?v=1671218437"
                     },
                     {
                        "title": "Hummel",
                        "url": "/collections/hummel-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/collections/180822_Kids_PCM_2.0.jpg?v=1675429555"
                     },
                     {
                        "title": "Hust & Claire",
                        "url": "/collections/hust-claire-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/5233259_bukser-trine-denim.jpg?v=1675179304"
                     },
                     {
                        "title": "Jack&Jones Junior",
                        "url": "/collections/jack-jones-junior-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/jj__4106353__front.jpg?v=1673395251"
                     },
                     {
                        "title": "Kattnakken",
                        "url": "/collections/kattnakken-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/panneband.001.jpg?v=1661444831"
                     },
                     {
                        "title": "Kids Only",
                        "url": "/collections/kids-only-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/KIDS-ONLY_Autumnwinter_3884526_15246735.jpg?v=1674839930"
                     },
                     {
                        "title": "Levis",
                        "url": "/collections/levis-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/levisrosa_439fc5fd-fc45-4c6d-ae4b-cab816f02f3c.jpg?v=1662560291"
                     },
                     {
                        "title": "Lil' Atelier",
                        "url": "/collections/lil-atelier-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/NAME-IT_Springsummer_4190199_13217166_1.jpg?v=1675424966"
                     },
                     {
                        "title": "LMTD",
                        "url": "/collections/lmtd-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/nit__3925029__detail.jpg?v=1675173620"
                     },
                     {
                        "title": "Lumimi",
                        "url": "/collections/lumimi-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/barneromslampe-handlaget-i-norge-av-furu-luftballong-rosa-lumimi-led.jpg?v=1646919167"
                     },
                     {
                        "title": "Lykkeland",
                        "url": "/collections/lykkeland-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/ttunwh03-lr-5_teething_toy_unicorn.jpg?v=1670516007"
                     },
                     {
                        "title": "Minymo",
                        "url": "/collections/minymo-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/bu.jpg?v=1668613266"
                     },
                     {
                        "title": "MP",
                        "url": "/collections/mp",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/10_79187_0_202.jpg?v=1665147227"
                     },
                     {
                        "title": "NAME IT",
                        "url": "/collections/name-it-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/nit__3947478__front.jpg?v=1674547709"
                     },
                     {
                        "title": "Nicki & Ellis",
                        "url": "/collections/nicki-ellis-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/nigu.jpg?v=1653087275"
                     },
                     {
                        "title": "På Stell",
                        "url": "/collections/pa-stell-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/Ullvaskhvit_f9e435e9-cfd7-48fc-bcf7-6967bc98e1d8.jpg?v=1653135503"
                     },
                     {
                        "title": "Papirkompaniet",
                        "url": "/collections/papirkompaniet-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/badebomberhjerte.jpg?v=1657633432"
                     },
                     {
                        "title": "Reima",
                        "url": "/collections/reima-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/lastned_4_7084f855-0995-4e94-b22f-a11e8de5a441.jpg?v=1662534459"
                     },
                     {
                        "title": "Sebra",
                        "url": "/collections/sebra-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/getimage_40.jpg?v=1618307602"
                     },
                     {
                        "title": "Sometime Soon",
                        "url": "/collections/sometime-soon-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/218122-2001_A.jpg?v=1653157816"
                     },
                     {
                        "title": "VILJE&VE",
                        "url": "/collections/vilje-ve-barn",
                        "type": "collection_link",
                        "active": false,
                        "child_active": false,
                        "featured_image": "//cdn.shopify.com/s/files/1/0247/6261/3815/products/ffff.gif?v=1667384467"
                     }
                  ]
               }
            ]
         }
      ]
   }
}