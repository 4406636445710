// make sure to import React to avoid issues with preact compat
import { style } from "motion"
import { CSSProperties, Fragment } from "react"

export type SVGIconProps = {
	color?: string
	size?: number
	fill?: string
	viewBox?: string
	strokeWidth?: number
	style?: CSSProperties
	name:
		| "x"
		| "chevron-right"
		| "chevron-left"
		| "chevron-down"
		| "chevron-up"
		| "burger"
		| "adjustments"
		| "user"
		| "facebook"
		| "instagram"
		| "youtube"
		| "square"
		| "checkbox"
		| "check"
		| "trash"
		| "search"
		| "check"
		| "grid"
		| "grid-2"
		| "swiper"
		| "arrow-narrow-left"
		| "arrow-narrow-right"
		| "cart-plus"
		| "cart-x"
		| "ABC"
		| "plus"
		| "minus"
		| "star"
		| "half-star"
		| "twitter"
		| "pinterest"
		| "eye"
		| "heart"
		| "person"
		| "tiktok"
		| "shopping-cart"
		| "align-left"
		| "align-center"
		| "align-right"
		| "info"
		| "truck"
		| "return"
		| "store"
		| "send"
		| "arrow-narrow-down"
		| "location"
}

const SVGIcon = (props: SVGIconProps): null | JSX.Element => {
	const strokeColor = props.color || "black"
	const size = props.size! || 26
	const viewBox = props.viewBox || `0 0 24 24`
	const strokeWidth = props.strokeWidth! || 1.5
	const fill = props.fill || "none"

	let svgInnerElem: null | JSX.Element = null

	if (props.name === "x") {
		svgInnerElem = (
			<Fragment>
				<path
					stroke="none"
					d="M0 0h24v24H0z"
					fill="none"
				/>
				<line
					x1="18"
					y1="6"
					x2="6"
					y2="18"
				/>
				<line
					x1="6"
					y1="6"
					x2="18"
					y2="18"
				/>
			</Fragment>
		)
	} else if (props.name === "heart") {
		svgInnerElem = (
			<Fragment>
				<path
					stroke="none"
					d="M0 0h24v24H0z"
					fill="none"></path>
				<path d="M19.5 13.572l-7.5 7.428l-7.5 -7.428m0 0a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572"></path>
			</Fragment>
		)
	} else if (props.name === "send") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="icon icon-tabler icon-tabler-send"
				width={size}
				height={size}
				viewBox={viewBox}
				strokeWidth={strokeWidth}
				stroke={strokeColor}
				fill={fill}
				style={props.style}
				strokeLinecap="round"
				strokeLinejoin="round">
				<path
					stroke="none"
					d="M0 0h24v24H0z"
					fill="none"
				/>
				<line
					x1="10"
					y1="14"
					x2="21"
					y2="3"
				/>
				<path d="M21 3l-6.5 18a0.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a0.55 .55 0 0 1 0 -1l18 -6.5" />
			</svg>
		)
	} else if (props.name === "arrow-narrow-down") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="icon icon-tabler icon-tabler-arrow-narrow-down"
				width={size}
				height={size}
				viewBox={viewBox}
				strokeWidth={strokeWidth}
				stroke={strokeColor}
				fill={fill}
				style={props.style}
				strokeLinecap="round"
				strokeLinejoin="round">
				<path
					stroke="none"
					d="M0 0h24v24H0z"
					fill="none"
				/>
				<line
					x1="12"
					y1="5"
					x2="12"
					y2="19"
				/>
				<line
					x1="16"
					y1="15"
					x2="12"
					y2="19"
				/>
				<line
					x1="8"
					y1="15"
					x2="12"
					y2="19"
				/>
			</svg>
		)
	} else if (props.name === "eye") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="icon icon-tabler icon-tabler-eye"
				width={size}
				height={size}
				viewBox={viewBox}
				strokeWidth={strokeWidth}
				stroke={strokeColor}
				fill={fill}
				style={props.style}
				strokeLinecap="round"
				strokeLinejoin="round">
				<path
					stroke="none"
					d="M0 0h24v24H0z"
					fill="none"></path>
				<circle
					cx="12"
					cy="12"
					r="2"></circle>
				<path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path>
			</svg>
		)
	} else if (props.name === "person") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				style={{
					width: `${size}px`,
					height: `${size}px`
				}}
				viewBox="0 0 20 20"
				fill="currentColor">
				<path
					fillRule="evenodd"
					d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
					clipRule="evenodd"
				/>
			</svg>
		)
	} else if (props.name === "shopping-cart") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="icon icon-tabler icon-tabler-shopping-cart"
				width={size}
				height={size}
				viewBox={viewBox}
				strokeWidth={strokeWidth}
				stroke={strokeColor}
				fill={fill}
				style={props.style}
				strokeLinecap="round"
				strokeLinejoin="round">
				<path
					stroke="none"
					d="M0 0h24v24H0z"
					fill="none"
				/>
				<circle
					cx="6"
					cy="21"
					r="1.5"
					fill="black"
				/>
				<circle
					cx="17"
					cy="21"
					r="1.5"
					fill="black"
				/>
				<path d="M17 17h-11v-14h-2" />
				<path d="M6 5l14 1l-1 7h-13" />
			</svg>
		)
	} else if (props.name === "location") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="icon icon-tabler icon-tabler-map-pin"
				width={size}
				height={size}
				viewBox={viewBox}
				strokeWidth={strokeWidth}
				stroke={strokeColor}
				fill={fill}
				style={props.style}
				strokeLinecap="round"
				strokeLinejoin="round">
				<path
					stroke="none"
					d="M0 0h24v24H0z"
					fill="none"
				/>
				<circle
					cx="12"
					cy="11"
					r="3"
				/>
				<path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
			</svg>
		)
	} else if (props.name === "cart-x") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="icon icon-tabler icon-tabler-shopping-cart-x"
				width={size}
				height={size}
				viewBox={viewBox}
				strokeWidth={strokeWidth}
				stroke={strokeColor}
				fill={fill}
				style={props.style}
				strokeLinecap="round"
				strokeLinejoin="round">
				<path
					stroke="none"
					d="M0 0h24v24H0z"
					fill="none"
				/>
				<circle
					cx="6"
					cy="19"
					r="2"
				/>
				<circle
					cx="17"
					cy="19"
					r="2"
				/>
				<path d="M17 17h-11v-14h-2" />
				<path d="M6 5l7.999 .571m5.43 4.43l-.429 2.999h-13" />
				<path d="M17 3l4 4" />
				<path d="M21 3l-4 4" />
			</svg>
		)
	} else if (props.name === "plus") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="icon icon-tabler icon-tabler-plus"
				width={size}
				height={size}
				viewBox={viewBox}
				strokeWidth={strokeWidth}
				stroke={strokeColor}
				style={props.style}
				fill="none"
				strokeLinecap="round"
				strokeLinejoin="round">
				<path
					stroke="none"
					d="M0 0h24v24H0z"
					fill="none"
				/>
				<line
					x1="12"
					y1="5"
					x2="12"
					y2="19"
				/>
				<line
					x1="5"
					y1="12"
					x2="19"
					y2="12"
				/>
			</svg>
		)
	} else if (props.name === "arrow-narrow-right") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="icon icon-tabler icon-tabler-arrow-narrow-right"
				width={size}
				height={size}
				viewBox={viewBox}
				strokeWidth={strokeWidth}
				stroke={strokeColor}
				fill="none"
				strokeLinecap="round"
				strokeLinejoin="round">
				<path
					stroke="none"
					d="M0 0h24v24H0z"
					fill="none"
				/>
				<line
					x1="5"
					y1="12"
					x2="19"
					y2="12"
				/>
				<line
					x1="15"
					y1="16"
					x2="19"
					y2="12"
				/>
				<line
					x1="15"
					y1="8"
					x2="19"
					y2="12"
				/>
			</svg>
		)
	} else if (props.name === "minus") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="icon icon-tabler icon-tabler-minus"
				width={size}
				height={size}
				viewBox={viewBox}
				strokeWidth={strokeWidth}
				stroke={strokeColor}
				fill="none"
				strokeLinecap="round"
				strokeLinejoin="round">
				<path
					stroke="none"
					d="M0 0h24v24H0z"
					fill="none"
				/>
				<line
					x1="5"
					y1="12"
					x2="19"
					y2="12"
				/>
			</svg>
		)
	} else if (props.name === "cart-plus") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="icon icon-tabler icon-tabler-shopping-cart-plus"
				width={size}
				height={size}
				viewBox={viewBox}
				strokeWidth={strokeWidth}
				stroke={strokeColor}
				fill={fill}
				style={props.style}
				strokeLinecap="round"
				strokeLinejoin="round">
				<path
					stroke="none"
					d="M0 0h24v24H0z"
					fill="none"
				/>
				<circle
					cx="6"
					cy="19"
					r="2"
				/>
				<circle
					cx="17"
					cy="19"
					r="2"
				/>
				<path d="M17 17h-11v-14h-2" />
				<path d="M6 5l6.005 .429m7.138 6.573l-.143 .998h-13" />
				<path d="M15 6h6m-3 -3v6" />
			</svg>
		)
	} else if (props.name === "ABC") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 24 24">
				<path
					d="M3 7A2 2 0 0 0 1 9V17H3V13H5V17H7V9A2 2 0 0 0 5 7H3M3 9H5V11H3M15 10.5V9A2 2 0 0 0 13 7H9V17H13A2 2 0 0 0 15 15V13.5A1.54 1.54 0 0 0 13.5 12A1.54 1.54 0 0 0 15 10.5M13 15H11V13H13V15M13 11H11V9H13M19 7A2 2 0 0 0 17 9V15A2 2 0 0 0 19 17H21A2 2 0 0 0 23 15V14H21V15H19V9H21V10H23V9A2 2 0 0 0 21 7Z"
					fill="white"></path>
			</svg>
		)
	} else if (props.name === "grid") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="icon icon-tabler icon-tabler-layout-grid"
				width={size}
				height={size}
				viewBox={viewBox}
				strokeWidth={strokeWidth}
				stroke={strokeColor}
				fill={fill}
				style={props.style}
				strokeLinecap="round"
				strokeLinejoin="round">
				<path
					stroke="none"
					d="M0 0h24v24H0z"
					fill="none"
				/>
				<rect
					x="4"
					y="4"
					width="6"
					height="6"
					rx="1"
				/>
				<rect
					x="14"
					y="4"
					width="6"
					height="6"
					rx="1"
				/>
				<rect
					x="4"
					y="14"
					width="6"
					height="6"
					rx="1"
				/>
				<rect
					x="14"
					y="14"
					width="6"
					height="6"
					rx="1"
				/>
			</svg>
		)
	} else if (props.name === "grid-2") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="icon icon-tabler icon-tabler-grid-dots"
				width={size}
				height={size}
				viewBox={viewBox}
				strokeWidth={strokeWidth}
				stroke={strokeColor}
				fill={fill}
				style={props.style}
				strokeLinecap="round"
				strokeLinejoin="round">
				<path
					stroke="none"
					d="M0 0h24v24H0z"
					fill="none"
				/>
				<rect
					x="5"
					y="5"
					width="2"
					height="2"
					fill="black"
				/>
				<rect
					x="12"
					y="5"
					width="2"
					height="2"
					fill="black"
				/>
				<rect
					x="19"
					y="5"
					width="2"
					height="2"
					fill="black"
				/>
				<rect
					x="5"
					y="12"
					width="2"
					height="2"
					fill="black"
				/>
				<rect
					x="12"
					y="12"
					width="2"
					height="2"
					fill="black"
				/>
				<rect
					x="19"
					y="12"
					width="2"
					height="2"
					fill="black"
				/>
				<rect
					x="5"
					y="19"
					width="2"
					height="2"
					fill="black"
				/>
				<rect
					x="12"
					y="19"
					width="2"
					height="2"
					fill="black"
				/>
				<rect
					x="19"
					y="19"
					width="2"
					height="2"
					fill="black"
				/>
			</svg>
		)
	} else if (props.name === "swiper") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="icon icon-tabler icon-tabler-square"
				width={size}
				height={size}
				viewBox={viewBox}
				strokeWidth={strokeWidth}
				stroke={strokeColor}
				fill={fill}
				style={props.style}
				strokeLinecap="round"
				strokeLinejoin="round">
				<path
					stroke="none"
					d="M0 0h24v24H0z"
					fill="none"
				/>
				<rect
					x="4"
					y="4"
					width="16"
					height="16"
					rx="2"
				/>
			</svg>
		)
	} else if (props.name === "search") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="icon icon-tabler icon-tabler-search"
				width={size}
				height={size}
				viewBox={viewBox}
				strokeWidth={strokeWidth}
				stroke={strokeColor}
				fill={fill}
				style={props.style}
				strokeLinecap="round"
				strokeLinejoin="round">
				<path
					stroke="none"
					d="M0 0h24v24H0z"
					fill="none"
				/>
				<circle
					cx="10"
					cy="10"
					r="7"
				/>
				<line
					x1="21"
					y1="21"
					x2="15"
					y2="15"
				/>
			</svg>
		)
	} else if (props.name === "chevron-up") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="icon icon-tabler icon-tabler-chevron-up"
				width={size}
				height={size}
				viewBox={viewBox}
				strokeWidth={strokeWidth}
				stroke={strokeColor}
				fill={fill}
				style={props.style}
				strokeLinecap="round"
				strokeLinejoin="round">
				<path
					stroke="none"
					d="M0 0h24v24H0z"
					fill="none"
				/>
				<polyline points="15 6 9 12 15 18" />
			</svg>
		)
	} else if (props.name === "chevron-left") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="icon icon-tabler icon-tabler-chevron-left"
				width={size}
				height={size}
				viewBox={viewBox}
				strokeWidth={strokeWidth}
				stroke={strokeColor}
				fill={fill}
				style={props.style}
				strokeLinecap="round"
				strokeLinejoin="round">
				<path
					stroke="none"
					d="M0 0h24v24H0z"
					fill="none"
				/>
				<polyline points="15 6 9 12 15 18" />
			</svg>
		)
	} else if (props.name === "chevron-right") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="icon icon-tabler icon-tabler-chevron-right"
				width={size}
				height={size}
				viewBox={viewBox}
				strokeWidth={strokeWidth}
				stroke={strokeColor}
				fill={fill}
				style={props.style}
				strokeLinecap="round"
				strokeLinejoin="round">
				<path
					stroke="none"
					d="M0 0h24v24H0z"
					fill="none"
				/>
				<polyline points="9 6 15 12 9 18" />
			</svg>
		)
	} else if (props.name === "chevron-down") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="icon icon-tabler icon-tabler-chevron-down"
				width={size}
				height={size}
				viewBox={viewBox}
				strokeWidth={strokeWidth}
				stroke={strokeColor}
				fill={fill}
				style={props.style}
				strokeLinecap="round"
				strokeLinejoin="round">
				<path
					stroke="none"
					d="M0 0h24v24H0z"
					fill="none"></path>
				<polyline points="6 9 12 13 18 9" />
			</svg>
		)
	} else if (props.name === "arrow-narrow-left") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="icon icon-tabler icon-tabler-arrow-narrow-left"
				width={size}
				height={size}
				viewBox={viewBox}
				strokeWidth={strokeWidth}
				stroke={strokeColor}
				fill={fill}
				style={props.style}
				strokeLinecap="round"
				strokeLinejoin="round">
				<path
					stroke="none"
					d="M0 0h24v24H0z"
					fill="none"
				/>
				<line
					x1="5"
					y1="12"
					x2="19"
					y2="12"
				/>
				<line
					x1="5"
					y1="12"
					x2="9"
					y2="16"
				/>
				<line
					x1="5"
					y1="12"
					x2="9"
					y2="8"
				/>
			</svg>
		)
	} else if (props.name === "burger") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="icon icon-tabler icon-tabler-menu-2"
				width={size}
				height={size}
				viewBox={viewBox}
				strokeWidth={strokeWidth}
				stroke={strokeColor}
				fill={fill}
				style={props.style}
				strokeLinecap="round">
				<path
					stroke="none"
					d="M0 0h24v24H0z"
					fill="none"></path>
				<line
					x1="4"
					y1="6"
					x2="20"
					y2="6"></line>
				<line
					x1="4"
					y1="12"
					x2="20"
					y2="12"></line>
				<line
					x1="4"
					y1="18"
					x2="20"
					y2="18"></line>
			</svg>
		)
	} else if (props.name === "adjustments") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="icon icon-tabler icon-tabler-adjustments"
				width={size}
				height={size}
				viewBox={viewBox}
				strokeWidth={strokeWidth}
				stroke={strokeColor}
				fill={fill}
				style={props.style}
				strokeLinecap="round">
				<path
					stroke="none"
					d="M0 0h24v24H0z"
					fill="none"></path>
				<circle
					cx="6"
					cy="10"
					r="2"></circle>
				<line
					x1="6"
					y1="4"
					x2="6"
					y2="8"></line>
				<line
					x1="6"
					y1="12"
					x2="6"
					y2="20"></line>
				<circle
					cx="12"
					cy="16"
					r="2"></circle>
				<line
					x1="12"
					y1="4"
					x2="12"
					y2="14"></line>
				<line
					x1="12"
					y1="18"
					x2="12"
					y2="20"></line>
				<circle
					cx="18"
					cy="7"
					r="2"></circle>
				<line
					x1="18"
					y1="4"
					x2="18"
					y2="5"></line>
				<line
					x1="18"
					y1="9"
					x2="18"
					y2="20"></line>
			</svg>
		)
	} else if (props.name === "user") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="icon icon-tabler icon-tabler-user"
				width={size}
				height={size}
				viewBox={viewBox}
				strokeWidth={strokeWidth}
				stroke={strokeColor}
				fill={fill}
				style={props.style}
				strokeLinecap="round">
				<path
					stroke="none"
					d="M0 0h24v24H0z"
					fill="none"></path>
				<circle
					cx="12"
					cy="7"
					r="4"></circle>
				<path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
			</svg>
		)
	} else if (props.name === "pinterest") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="icon icon-tabler icon-tabler-brand-pinterest"
				width={size}
				height={size}
				viewBox={viewBox}
				strokeWidth={strokeWidth}
				stroke={strokeColor}
				fill={fill}
				style={props.style}
				strokeLinecap="round">
				<path
					stroke="none"
					d="M0 0h24v24H0z"
					fill="none"></path>
				<line
					x1="8"
					y1="20"
					x2="12"
					y2="11"></line>
				<path d="M10.7 14c.437 1.263 1.43 2 2.55 2c2.071 0 3.75 -1.554 3.75 -4a5 5 0 1 0 -9.7 1.7"></path>
				<circle
					cx="12"
					cy="12"
					r="9"></circle>
			</svg>
		)
	} else if (props.name === "twitter") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="icon icon-tabler icon-tabler-brand-twitter"
				width={size}
				height={size}
				viewBox={viewBox}
				strokeWidth={strokeWidth}
				stroke={strokeColor}
				fill={fill}
				style={props.style}
				strokeLinecap="round">
				<path
					stroke="none"
					d="M0 0h24v24H0z"
					fill="none"></path>
				<path d="M22 4.01c-1 .49 -1.98 .689 -3 .99c-1.121 -1.265 -2.783 -1.335 -4.38 -.737s-2.643 2.06 -2.62 3.737v1c-3.245 .083 -6.135 -1.395 -8 -4c0 0 -4.182 7.433 4 11c-1.872 1.247 -3.739 2.088 -6 2c3.308 1.803 6.913 2.423 10.034 1.517c3.58 -1.04 6.522 -3.723 7.651 -7.742a13.84 13.84 0 0 0 .497 -3.753c-.002 -.249 1.51 -2.772 1.818 -4.013z"></path>
			</svg>
		)
	} else if (props.name === "facebook") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="icon icon-tabler icon-tabler-brand-facebook"
				width={size}
				height={size}
				viewBox={viewBox}
				strokeWidth={strokeWidth}
				stroke={strokeColor}
				fill={fill}
				style={props.style}
				strokeLinecap="round">
				<path
					stroke="none"
					d="M0 0h24v24H0z"
					fill="none"></path>
				<path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"></path>
			</svg>
		)
	} else if (props.name === "tiktok") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="icon icon-tabler icon-tabler-brand-tiktok"
				width={size}
				height={size}
				viewBox={viewBox}
				strokeWidth={strokeWidth}
				stroke={strokeColor}
				fill={fill}
				style={props.style}
				strokeLinecap="round"
				strokeLinejoin="round">
				<path
					stroke="none"
					d="M0 0h24v24H0z"
					fill="none"
				/>
				<path d="M9 12a4 4 0 1 0 4 4v-12a5 5 0 0 0 5 5" />
			</svg>
		)
	} else if (props.name === "star") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="icon icon-tabler icon-tabler-star"
				width={size}
				height={size}
				viewBox={viewBox}
				strokeWidth={strokeWidth}
				stroke={strokeColor}
				fill={fill}
				style={props.style}
				strokeLinecap="round"
				strokeLinejoin="round">
				<path
					stroke="none"
					d="M0 0h24v24H0z"
					fill="none"
				/>
				<path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z" />
			</svg>
		)
	} else if (props.name === "info") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="icon icon-tabler icon-tabler-info-circle"
				width={size}
				height={size}
				viewBox={viewBox}
				strokeWidth={strokeWidth}
				stroke={strokeColor}
				fill={fill}
				style={props.style}
				strokeLinecap="round"
				strokeLinejoin="round">
				<path
					stroke="none"
					d="M0 0h24v24H0z"
					fill="none"
				/>
				<circle
					cx="12"
					cy="12"
					r="9"
				/>
				<line
					x1="12"
					y1="8"
					x2="12.01"
					y2="8"
				/>
				<polyline points="11 12 12 12 12 16 13 16" />
			</svg>
		)
	} else if (props.name === "half-star") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="icon icon-tabler icon-tabler-star-half"
				width={size}
				height={size}
				viewBox={viewBox}
				strokeWidth={strokeWidth}
				stroke={strokeColor}
				fill={fill}
				style={props.style}
				strokeLinecap="round"
				strokeLinejoin="round">
				<path
					stroke="none"
					d="M0 0h24v24H0z"
					fill="none"
				/>
				<path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253z" />
			</svg>
		)
	} else if (props.name === "instagram") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="icon icon-tabler icon-tabler-brand-instagram"
				width={size}
				height={size}
				viewBox={viewBox}
				strokeWidth={strokeWidth}
				stroke={strokeColor}
				fill="none"
				strokeLinecap="round">
				<path
					stroke="none"
					d="M0 0h24v24H0z"
					fill="none"></path>
				<rect
					x="4"
					y="4"
					width="16"
					height="16"
					rx="4"></rect>
				<circle
					cx="12"
					cy="12"
					r="3"></circle>
				<line
					x1="16.5"
					y1="7.5"
					x2="16.5"
					y2="7.501"></line>
			</svg>
		)
	} else if (props.name === "youtube") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="icon icon-tabler icon-tabler-brand-youtube"
				width={size}
				height={size}
				viewBox={viewBox}
				strokeWidth={strokeWidth}
				stroke={strokeColor}
				fill={fill}
				style={props.style}
				strokeLinecap="round">
				<path
					stroke="none"
					d="M0 0h24v24H0z"
					fill="none"></path>
				<rect
					x="3"
					y="5"
					width="18"
					height="14"
					rx="4"></rect>
				<path d="M10 9l5 3l-5 3z"></path>
			</svg>
		)
	} else if (props.name === "square") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="icon icon-tabler icon-tabler-square"
				width={size}
				height={size}
				viewBox={viewBox}
				strokeWidth={strokeWidth}
				stroke={strokeColor}
				fill={fill}
				style={props.style}
				strokeLinecap="round">
				<path
					stroke="none"
					d="M0 0h24v24H0z"
					fill="none"></path>
				<rect
					x="4"
					y="4"
					width="16"
					height="16"
					rx="2"></rect>
			</svg>
		)
	} else if (props.name === "trash") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="icon icon-tabler icon-tabler-trash"
				width={size}
				height={size}
				viewBox={viewBox}
				strokeWidth={strokeWidth}
				stroke={strokeColor}
				fill={fill}
				style={props.style}
				strokeLinecap="round"
				strokeLinejoin="round">
				<path
					stroke="none"
					d="M0 0h24v24H0z"
					fill="none"
				/>
				<line
					x1="4"
					y1="7"
					x2="20"
					y2="7"
				/>
				<line
					x1="10"
					y1="11"
					x2="10"
					y2="17"
				/>
				<line
					x1="14"
					y1="11"
					x2="14"
					y2="17"
				/>
				<path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
				<path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
			</svg>
		)
	} else if (props.name === "checkbox") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="icon icon-tabler icon-tabler-checkbox"
				width={size}
				height={size}
				viewBox={viewBox}
				strokeWidth={strokeWidth}
				stroke={strokeColor}
				fill="none"
				strokeLinecap="round">
				<path
					stroke="none"
					d="M0 0h24v24H0z"
					fill="none"></path>
				<polyline points="9 11 12 14 20 6"></polyline>
				<path d="M20 12v6a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h9"></path>
			</svg>
		)
	} else if (props.name === "align-left") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="icon icon-tabler icon-tabler-align-left"
				width={size}
				height={size}
				viewBox={viewBox}
				strokeWidth={strokeWidth}
				stroke={strokeColor}
				fill={fill}
				style={props.style}
				strokeLinecap="round"
				strokeLinejoin="round">
				<path
					stroke="none"
					d="M0 0h24v24H0z"
					fill="none"
				/>
				<line
					x1="4"
					y1="6"
					x2="20"
					y2="6"
				/>
				<line
					x1="4"
					y1="12"
					x2="14"
					y2="12"
				/>
				<line
					x1="4"
					y1="18"
					x2="18"
					y2="18"
				/>
			</svg>
		)
	} else if (props.name === "align-center") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="icon icon-tabler icon-tabler-align-left"
				width={size}
				height={size}
				viewBox={viewBox}
				strokeWidth={strokeWidth}
				stroke={strokeColor}
				fill={fill}
				style={props.style}
				strokeLinecap="round"
				strokeLinejoin="round">
				<path
					stroke="none"
					d="M0 0h24v24H0z"
					fill="none"
				/>
				<line
					x1="4"
					y1="6"
					x2="20"
					y2="6"
				/>
				<line
					x1="8"
					y1="12"
					x2="16"
					y2="12"
				/>
				<line
					x1="6"
					y1="18"
					x2="18"
					y2="18"
				/>
			</svg>
		)
	} else if (props.name === "align-right") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="icon icon-tabler icon-tabler-align-left"
				width={size}
				height={size}
				viewBox={viewBox}
				strokeWidth={strokeWidth}
				stroke={strokeColor}
				fill={fill}
				style={props.style}
				strokeLinecap="round"
				strokeLinejoin="round">
				<path
					stroke="none"
					d="M0 0h24v24H0z"
					fill="none"
				/>
				<line
					x1="4"
					y1="6"
					x2="20"
					y2="6"
				/>
				<line
					x1="10"
					y1="12"
					x2="20"
					y2="12"
				/>
				<line
					x1="6"
					y1="18"
					x2="20"
					y2="18"
				/>
			</svg>
		)
	}

	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	else if (props.name === "check") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="icon icon-tabler icon-tabler-check"
				width={size}
				height={size}
				viewBox={viewBox}
				strokeWidth={strokeWidth}
				stroke={strokeColor}
				fill={fill}
				style={props.style}
				strokeLinecap="round">
				<path
					stroke="none"
					d="M0 0h24v24H0z"
					fill="none"></path>
				<path d="M5 12l5 5l10 -10"></path>
			</svg>
		)
	} else if (props.name === "truck") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={size}
				height={size}
				viewBox={viewBox}
				strokeWidth={strokeWidth}
				stroke={strokeColor}
				fill={fill}
				style={props.style}
				strokeLinecap="round"
				strokeLinejoin="round">
				<path
					stroke="none"
					d="M0 0h24v24H0z"
					fill="none"
				/>
				<circle
					cx="7"
					cy="17"
					r="2"
				/>
				<circle
					cx="17"
					cy="17"
					r="2"
				/>
				<path d="M5 17h-2v-11a1 1 0 0 1 1 -1h9v12m-4 0h6m4 0h2v-6h-8m0 -5h5l3 5" />
			</svg>
		)
	} else if (props.name === "return") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="icon icon-tabler icon-tabler-truck-return"
				width={size}
				height={size}
				viewBox={viewBox}
				strokeWidth={strokeWidth}
				stroke={strokeColor}
				fill={fill}
				style={props.style}
				strokeLinecap="round"
				strokeLinejoin="round">
				<path
					stroke="none"
					d="M0 0h24v24H0z"
					fill="none"
				/>
				<circle
					cx="7"
					cy="17"
					r="2"
				/>
				<circle
					cx="17"
					cy="17"
					r="2"
				/>
				<path d="M5 17h-2v-11a1 1 0 0 1 1 -1h9v6h-5l2 2m0 -4l-2 2" />
				<line
					x1="9"
					y1="17"
					x2="15"
					y2="17"
				/>
				<path d="M13 6h5l3 5v6h-2" />
			</svg>
		)
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	} else if (props.name === "store") {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="icon icon-tabler icon-tabler-building-store"
				width={size}
				height={size}
				viewBox={viewBox}
				strokeWidth={strokeWidth}
				stroke={strokeColor}
				fill={fill}
				style={props.style}
				strokeLinecap="round"
				strokeLinejoin="round">
				<path
					stroke="none"
					d="M0 0h24v24H0z"
					fill="none"
				/>
				<line
					x1="3"
					y1="21"
					x2="21"
					y2="21"
				/>
				<path d="M3 7v1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1h-18l2 -4h14l2 4" />
				<line
					x1="5"
					y1="21"
					x2="5"
					y2="10.85"
				/>
				<line
					x1="19"
					y1="21"
					x2="19"
					y2="10.85"
				/>
				<path d="M9 21v-4a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v4" />
			</svg>
		)
	} else {
		const _n: never = props.name
		return null
	}

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className={`icon icon-tabler icon-tabler-${props.name}`}
			width={size}
			height={size}
			viewBox={viewBox}
			strokeWidth={strokeWidth}
			stroke={strokeColor}
			fill={fill}
			style={props.style}
			strokeLinecap="round"
			strokeLinejoin="round">
			{svgInnerElem}
		</svg>
	)
}

export default SVGIcon
