import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import ReactDOM from "react-dom"

import { modifyShopifyImageSrc } from "../../shared/utils/modifyShopifyImageSrc"
import ProductOptions from "../ProductOptions"
import SVGIcon from "~/components/ui/SVGIcon"
import SwiperWrapper from "~/components/Swiper"

import Spinner from "~/components/ui/Spinner"
import type { App } from "~/types"
import { formatMoney } from "~/shared/utils"
import { storefrontAPI } from "~/shared/storefront-api/StorefrontAPI"
import { useGlobalStore } from "~/shared/useGlobalStore"
import { ParsedBoostProduct } from "../../modules/search/SearchController"
import { isParsedBoostProduct } from "../product-card/ProductCard"
type Props = {
	product: App.StorefrontAPI.Product | ParsedBoostProduct
	hideAddToCartWidget: () => void
}

type State = {
	inProgress: boolean
	isSelected: null | string
}

const AddToCartWidget = (props: Props) => {
	const [state, setState] = useState<State>({
		// selectedOptions: getDefaultSelectedOptions({ product: props.product }),
		inProgress: false,
		isSelected: null
	})
	const { product } = props

	// TODO: update based on store info
	const currencyCode = "NOK"

	const variants: Array<
		Pick<
			App.StorefrontAPI.ProductVariant,
			"availableForSale" | "selectedOptions" | "price" | "id" | "title"
		>
	> = useMemo(() => {
		return isParsedBoostProduct(product)
			? product.variants.map((variant) => {
					return {
						id: `gid://shopify/ProductVariant/${variant.id}`,
						price: {
							currencyCode,
							amount: variant.price.toString()
						},
						title: variant.title,
						availableForSale: variant.available,
						selectedOptions: variant.selectedOptions
					}
			  })
			: product.variants
	}, [product])

	const [selectedVariant, setSelectedVariant] = useState<null | (typeof variants)[0]>(
		null
	)
	const containerRef = useRef<null | HTMLDivElement>(null)
	const backdropRef = useRef<null | HTMLDivElement>(null)

	const backdropMaxOpacity = 0.5

	const animateCb = useCallback(
		(data: { isVisible: boolean }) => {
			const isVisible = data.isVisible

			if (backdropRef.current == null) {
				console.error("FD: AddToCartWidget.backdropRef is not defined")
				return
			}
			if (containerRef.current == null) {
				console.error("FD: AddRoCartWidget.containerRef is not defined")
				return
			}

			if (isVisible === true) {
				backdropRef.current.style.display = "block"
				setTimeout(() => {
					containerRef.current!.style.transform = "translate(-50%, 0%)"
					backdropRef.current!.style.opacity = `${backdropMaxOpacity}`
				}, 50)
			} else {
				backdropRef.current!.style.opacity = `0`
				backdropRef.current.style.display = "none"
				containerRef.current.style.transform = "translate(-50%, 130%)"
				// reset the scroll
				containerRef.current.scrollTop = 0

				setTimeout(() => {
					props.hideAddToCartWidget()
				}, 100)
			}
		},
		[props]
	)

	useEffect(() => {
		animateCb({ isVisible: true })
	}, [animateCb])

	const onSelectVariantCb = useCallback((variant: typeof selectedVariant) => {
		setSelectedVariant(variant)
	}, [])

	const baseZIndex = 100000000

	const availableForSale = isParsedBoostProduct(product)
		? product.available
		: product.availableForSale

	const productImages = isParsedBoostProduct(product)
		? product.media.map((media) => {
				return {
					url: media.src
				}
		  })
		: product.images

	const priceRange = product.priceRange

	return ReactDOM.createPortal(
		<>
			<div
				ref={backdropRef}
				onClick={() => {
					animateCb({ isVisible: false })
				}}
				style={{
					top: 0,
					left: 0,
					width: "100%",
					height: "100%",
					// must be lower than the add-to-cart-widget-container
					zIndex: baseZIndex - 10,
					willChange: "opacity",
					opacity: 0,
					transition: "opacity .01s cubic-bezier(0.165, 0.84, 0.44, 1)",
					position: "fixed",
					backgroundColor: "black"
				}}
			/>
			<div
				style={{
					bottom: 0,
					left: "50%",
					width: "100%",
					height: "100%",
					maxWidth: "550px",
					maxHeight: "80vh",
					overflowY: "auto",
					position: "fixed",
					zIndex: baseZIndex,
					backgroundColor: "white",
					borderTopLeftRadius: "15px",
					borderTopRightRadius: "15px",
					boxShadow: "1px 1px 15px rgba(0, 0, 0, 0.1)",
					transform: "translate(-50%, 130%)",
					transition: "transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1)"
				}}
				ref={containerRef}
				className="add-to-cart-widget-container">
				<div
					style={{
						top: 6,
						zIndex: 100,
						position: "sticky",
						// shift contenet below it upwards
						marginBottom: -30
					}}>
					<div
						style={{
							marginRight: 6,
							width: 30,
							height: 30,
							cursor: "pointer",
							borderRadius: "50%",
							background: "white",
							position: "relative",
							marginLeft: "auto",
							border: "1px solid #f1f1f1",
							boxShadow: "1px 1px 6px rgba(0, 0, 0, 0.05)"
						}}
						onClick={() => {
							animateCb({ isVisible: false })
						}}>
						<div
							data-test="x"
							className="transform-centered">
							<SVGIcon
								name="x"
								size={22}
								color="black"
							/>
						</div>
					</div>
				</div>

				<div>
					<SwiperWrapper id="AddToCart">
						{productImages.map((image, i) => {
							// old versions of safari seem to need an element wrapped around the img so don't remove it
							return (
								<div
									className="swiper-slide"
									style={{
										width: "100%",
										flexShrink: 0
									}}
									key={image.url}>
									<picture>
										<img
											style={{
												aspectRatio: "1/1",
												width: "100%",
												objectFit: "contain"
											}}
											loading={i === 0 ? undefined : "lazy"}
											src={modifyShopifyImageSrc(image.url, {
												width: 1500
											})}
										/>
									</picture>
								</div>
							)
						})}
					</SwiperWrapper>
				</div>

				<div className="p-4 space-y-4 uppercase">
					<div className="space-y-1">
						<h4 data-test="product-title">{product.title}</h4>
					</div>

					<ProductOptions
						product={{
							handle: product.handle,
							options: product.options,
							variants
						}}
						defaultSelectedOptions={selectedVariant?.selectedOptions || null}
						onSelectVariant={onSelectVariantCb}
					/>

					{selectedVariant == null ? (
						<div style={{ fontSize: "18px", fontWeight: "bolder" }}>
							{priceRange.minVariantPrice.amount ===
							priceRange.maxVariantPrice.amount ? (
								formatMoney({
									price: priceRange.minVariantPrice
								})
							) : (
								<div>
									{formatMoney({
										price: priceRange.minVariantPrice
									})}{" "}
									-{" "}
									{formatMoney({
										price: priceRange.minVariantPrice
									})}
								</div>
							)}
						</div>
					) : selectedVariant.availableForSale === true ? (
						<div style={{ fontSize: "18px", fontWeight: "bolder" }}>
							Price :{" "}
							{formatMoney({
								price: selectedVariant.price
							})}
						</div>
					) : (
						<span style={{ fontSize: "18px", fontWeight: "bolder" }}>Sold Out</span>
					)}

					<button
						data-test="add-to-bag"
						className="widget-add-to-cart relative"
						disabled={selectedVariant?.availableForSale !== true}
						style={{
							width: "100%",
							fontSize: 20,
							color: "#fff",
							padding: "10px 0px",
							opacity:
								state.inProgress === true || availableForSale !== true
									? "0.5"
									: "1",
							border:
								availableForSale !== true
									? "1px solid black"
									: "1px solid var(--color-cta)",
							backgroundColor:
								availableForSale !== true ? "black" : "var(--color-cta)"
						}}
						// eslint-disable-next-line @typescript-eslint/no-misused-promises
						onClick={async (e) => {
							// Guard
							e.preventDefault()
							if (selectedVariant?.availableForSale !== true) {
								return
							}

							setState({
								...state,
								inProgress: true
							})

							let cart = await storefrontAPI.getCart()

							await storefrontAPI.addCartItem({
								quantity: 1,
								variant: selectedVariant,
								cart: {
									id: cart.id
								}
							})

							cart = await storefrontAPI.getCart()

							useGlobalStore.setState({
								showSlideoutCart: true
							})
							animateCb({ isVisible: false })
						}}>
						{selectedVariant == null ? (
							<span style={{ fontSize: 18 }}>Select All Options</span>
						) : selectedVariant.availableForSale === true ? (
							<div>
								<span
									style={{
										visibility:
											state.inProgress === true ? "hidden" : "visible",
										display: state.inProgress === true ? "none" : "block"
									}}>
									Add To Bag
								</span>
								<span
									style={{
										visibility:
											state.inProgress === true ? "visible" : "hidden",
										display: state.inProgress === true ? "block" : "none"
									}}>
									<Spinner />
								</span>
							</div>
						) : (
							<div>Sold Out</div>
						)}
					</button>
				</div>
			</div>
		</>,
		document.body
	)
}
export default AddToCartWidget
